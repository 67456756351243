import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ErrorSectionComponent} from "./_components/error-section/error-section.component";
import {ContactRegistrationSectionComponent} from './catalog/registration-form/contact-registration-section.component';
import {MarketplaceUseCaseComponent} from "./marketplace/marketplace-use-case.component";

const routes: Routes = [
  {
    path: 'catalog',
    loadChildren: './catalog/catalog.module#CatalogModule'
  },
  {
    path: 'admin',
    loadChildren: './admin-panel/admin-panel.module#AdminPanelModule'
  },
  {
    path: 'public-catalog',
    loadChildren: './catalog/catalog.module#CatalogModule'
  },
  {
    path: 'integration',
    loadChildren: './integration/integration.module#IntegrationModule'
  },
  {
    path: 'error',
    component: ErrorSectionComponent
  },
  {
    path: 'contactRegistration/:modality',
    component: ContactRegistrationSectionComponent
  },
  {
    path: 'marketplace',
    component: MarketplaceUseCaseComponent
  },
  {path: '**', redirectTo: '/public-catalog', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
