import {Component, OnInit} from '@angular/core';
import {ProfileService} from "./_services/profile.service";
import {NavigationEnd, Router} from '@angular/router';
import {ScriptService} from './_services/script.service';
import {MARKETPLACE_USE_CASES} from './_costants/marketplace-use-cases';

declare let Appcues: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private profileService: ProfileService,
              private router: Router,
              private script: ScriptService) {
  }

  ngOnInit(): void {
    this.loadAppcues();
  }

  loadAppcues() {
    this.script.load('appcues').then(data => {
      console.info('script loaded ', data);
      if (data[0].loaded) {
        Appcues.page();
        this.setupAppcuesSession();
        this.router.events.subscribe(event => {
          if (event instanceof NavigationEnd) {
            Appcues.page();
          }
        });
      } else {
        this.setupSessionWithoutAppcues();
      }
    }).catch(error => console.error(error));
  }

  private setupAppcuesSession() {
    let userId = '';
    this.profileService.initLoggedUser().subscribe(success => {
      if (success) {
        userId = this.profileService.loggedUser.userId;
        Appcues.identify(
          userId
        );
        console.info('Authenticated user loaded');
      } else {
        Appcues.anonymous();
        console.info('User not authenticated');
      }
    });
  }


  private setupSessionWithoutAppcues() {
    let userId = '';
    this.profileService.initLoggedUser().subscribe(success => {
      if (success) {
        userId = this.profileService.loggedUser.userId;
        console.info('Authenticated user loaded');
      } else {
        console.info('User not authenticated');
      }
    });
  }

}
