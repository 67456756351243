import { OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { IntegrationDataRequest } from "../../_model/integration-data-request.model";
import { IntegrationService } from "../../_services/integration.service";
var FormPublisherComponent = /** @class */ (function () {
    function FormPublisherComponent(route, integrationService) {
        this.route = route;
        this.integrationService = integrationService;
        this._isFpId = true;
    }
    FormPublisherComponent.prototype.ngOnInit = function () {
        var fp_id = this.route.snapshot.params.fp_id;
        if (fp_id) {
            this.fetchLibrary(fp_id);
        }
        else {
            this._isFpId = false;
        }
    };
    FormPublisherComponent.prototype.fetchLibrary = function (fp_id) {
        var _this = this;
        var integrationRequest = new IntegrationDataRequest();
        integrationRequest.formPublisherId = fp_id;
        this.integrationService.get(integrationRequest).subscribe(function (resp) {
            if (resp) {
                _this._integrationDataResponse = resp;
                if (_this._integrationDataResponse.link) {
                    location.href = _this._integrationDataResponse.link;
                }
                else if (_this._integrationDataResponse.inProgress) {
                    setTimeout(function () {
                        location.reload();
                    }, 15000);
                }
            }
        }, function (err) {
            console.error("error", err);
        });
    };
    Object.defineProperty(FormPublisherComponent.prototype, "integrationDataResponse", {
        get: function () {
            return this._integrationDataResponse;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormPublisherComponent.prototype, "fpIdExists", {
        get: function () {
            return this._isFpId;
        },
        enumerable: true,
        configurable: true
    });
    return FormPublisherComponent;
}());
export { FormPublisherComponent };
