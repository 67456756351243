<app-simple-toolbar></app-simple-toolbar>
<div class="main">
  <section *ngIf="!useCaseData">
    <p>No library found</p>
  </section>
  <section *ngIf="useCaseData.id">
    <section class="use-case-header" fxLayout="row" fxLayoutAlign="center none" fxLayout.lt-md="column"
             fxLayoutAlign.lt-md="center">
      <div fxFlex="45%" style="padding-top: 20px">
        <h1>{{useCaseData.libraryTemplateName}}</h1>
        <p class="use-case-description">{{useCaseData.description}}</p>
        <div class="button-container" fxLayout="row" fxLayoutAlign="start end">
          <div>
            <button mat-flat-button class="button" color="primary" style="margin-right:30px"
                    (click)="goToNext()">
          <span fxLayout="row" fxLayoutAlign="space-between center">
            Try now!
          </span>
            </button>
          </div>
          <div>
            <button mat-stroked-button class="button contact-button" color="primary" (click)="contactUs()">
          <span fxLayout="row" fxLayoutAlign="space-between center">
              Contact us
          </span>
            </button>
          </div>
        </div>
      </div>
      <div fxFlex="45%">
        <img class="welcome-img"
             src="{{useCaseData.landingPageContent.welcomeImage ?  useCaseData.landingPageContent.welcomeImage : '../assets/human-resources-2x.png'}}">
      </div>
    </section>
    <section class="separator">
      <img width="60px" height="49px" src="../assets/triangles.svg" style="margin-bottom: 30px">
      <p class="sub-title" style="text-align: center; width: 80%">{{useCaseData.landingPageContent.subDescription}}</p>
    </section>
    <section class="widgets" fxLayout="row wrap" fxLayoutAlign="center stretch"
             fxLayout.lt-md="column" fxLayoutAlign.lt-md="center">
      <div class="widget-container" *ngFor="let widget of useCaseData.landingPageContent.widgets" fxLayout="row"
           fxLayoutAlign="center stretch" fxFlex.lt-md="100">
        <mat-card class="widget-item" fxLayout="column">
          <img class="widget-img"
               src="{{ widget.image ? widget.image : '../assets/default_widget_img.svg'}}"/>
          <p class="sub-title" style="text-align: center">{{widget.title}}</p>
          <p class="sub-description" style="text-align: center">{{widget.description}}</p>
        </mat-card>
      </div>
    </section>
    <section class="detail-containers">



      <div class="detail-container"
           *ngFor="let detailContainer of useCaseData.landingPageContent.detailContainers; let odd = odd;"
         >

        <div *ngIf="!odd"   fxLayout="row"
             fxLayoutAlign="space-evenly center"
             fxLayout.lt-md="column">
        <div class="detail-text" fxFlex="40%" fxFlex.sm="" fxFlex.xs="">
          <div *ngFor="let detail of detailContainer.details" fxLayout="column">
            <p class="sub-title" style="margin-bottom: 0">{{detail.title}}</p>
            <p class="sub-description">{{detail.description}}</p>
          </div>
        </div>

        <div fxFlex="40%" fxFlex.sm="" fxFlex.xs="" style="padding-top: 30px;">
          <img class="detail-img" alt=""
               src="{{ detailContainer.image ? detailContainer.image : '../assets/detail_image.png'}}"/>
        </div>
        </div>


        <div *ngIf="odd"  fxLayout="row"
             fxLayoutAlign="space-evenly center"
             fxLayout.lt-md="column">
          <div fxFlex="40%" fxFlex.sm="" fxFlex.xs="" style="padding-top: 30px;">
            <img class="detail-img" alt=""
                 src="{{ detailContainer.image ? detailContainer.image : '../assets/detail_image.png'}}"/>
          </div>
          <div class="detail-text" fxFlex="40%" fxFlex.sm="" fxFlex.xs="">
            <div *ngFor="let detail of detailContainer.details" fxLayout="column">
              <p class="sub-title" style="margin-bottom: 0">{{detail.title}}</p>
              <p class="sub-description">{{detail.description}}</p>
            </div>
          </div>


        </div>


      </div>
    </section>
    <section class="end">
      <h1>Ready to get started?</h1>
      <div class="button-container" fxLayout="row" fxLayoutAlign="start end">
        <div>
          <button mat-raised-button class="button" color="accent" style="margin-right:30px"
                  (click)="goToNext()">
          <span fxLayout="row" fxLayoutAlign="space-between center">
            Try now!
          </span>
          </button>
        </div>
        <div>
          <button mat-stroked-button class="button button-end" color="primary" (click)="contactUs()">
          <span fxLayout="row" fxLayoutAlign="space-between center">
              Contact us
          </span>
          </button>
        </div>
      </div>
    </section>
  </section>
</div>
<app-mat-footer></app-mat-footer>

