import {Component, Input, OnInit} from '@angular/core';
import {CatalogItem} from "../../_model/catalog-item.model";
import {Router} from '@angular/router';
import {RedirectHelperService} from '../../_services/redirect-helper.service';

@Component({
  selector: 'app-big-catalog-item',
  templateUrl: './big-catalog-item.component.html',
  styleUrls: ['./big-catalog-item.component.scss']
})
export class BigCatalogItemComponent implements OnInit {
  url: string;
  buyLibraryUrl: string;
  private _startingPrice;

  constructor(private router: Router,
              private redirectHelperService: RedirectHelperService) {
  }

  @Input()
  item: CatalogItem;

  private _bulletPoints: Array<string> = [];
  private _redirectToHubspotForm: boolean = false;

  get bulletPoints(): Array<string> {
    return this._bulletPoints;
  }

  get redirectToHubspotForm(): boolean {
    return this._redirectToHubspotForm;
  }

  ngOnInit() {
    if (this.item.bulletPoints) {
      this._bulletPoints = this.item.bulletPoints.split(';');
    }
    if (this.router.url === '/public-catalog') {
      this._redirectToHubspotForm = true;
    }
    this._startingPrice = this.item.userByVolumeChargifyComponent.prices[0].unit_price;
  }

  moveToNext(itemId: string) {
    if (!this.item.purchaseLink) {
      this.newCustomer(itemId);
    } else {
      location.href = this.item.purchaseLink;
    }
  }

  newCustomer(itemId: string) {
    const modality = "trial";
    this.saveRedirectUrl(itemId);
    if (this.redirectToHubspotForm) {
      this.router.navigate(['/contactRegistration/' + modality]);
    } else {
      this.router.navigate(['/catalog/buy/' + itemId]);
    }
  }

  moveToNextSection(itemId: string, isTrial: boolean) {
    itemId = isTrial ? itemId + "-trial" : itemId;
    const modality = isTrial ? "trial" : "purchase";
    this.saveRedirectUrl(itemId);
    if (this.redirectToHubspotForm) {
      this.router.navigate(['/contactRegistration/' + modality]);
    } else {
      this.router.navigate(['/catalog/buy/' + itemId]);
    }
  }

  saveRedirectUrl(itemId: string) {
    this.buyLibraryUrl = '/catalog/buy/' + itemId;
    this.redirectHelperService.url = this.buyLibraryUrl;
    this.redirectHelperService.catalogItemId = itemId;
  }

  get startingPrice(): number {
    return this._startingPrice;
  }
}
