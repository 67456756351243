import { OnInit } from '@angular/core';
var SimpleDialogComponent = /** @class */ (function () {
    function SimpleDialogComponent(data) {
        this._message = '';
        this._title = '';
        this._confirmButtonLabel = '';
        this._message = data.message;
        this._title = data.title;
        this._confirmButtonLabel = data.confirmButtonLabel;
    }
    Object.defineProperty(SimpleDialogComponent.prototype, "message", {
        get: function () {
            return this._message;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SimpleDialogComponent.prototype, "title", {
        get: function () {
            return this._title;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SimpleDialogComponent.prototype, "confirmButtonLabel", {
        get: function () {
            return this._confirmButtonLabel;
        },
        enumerable: true,
        configurable: true
    });
    SimpleDialogComponent.prototype.ngOnInit = function () {
    };
    return SimpleDialogComponent;
}());
export { SimpleDialogComponent };
