import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SimpleDialogComponent} from "../_components/simple-dialog/simple-dialog.component";
import {ProfileService} from "../_services/profile.service";
import {ResponsiveModule} from "ngx-responsive";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MaterialElementsModule} from "./material-elements.module";
import {PriceTagComponent} from "../_components/price-tag/price-tag.component";
import {PriceTagBannerComponent} from "../_components/price-tag-banner/price-tag-banner.component";
import {SafeUrlPipe} from "../_pipes/safe-url.pipe";
import {CapitalizePipe} from '../_pipes/capitalize.pipe';
import {LibSharingConfigComponent} from "../_components/lib-sharing-config/lib-sharing-config.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {LibRolesDescriptionComponent} from "../_components/lib-roles-description/lib-roles-description.component";
import {LibraryMetadataService} from "../_services/library-metadata.service";
import {ChargifyTokenService} from "../_services/chargify-token.service";
import {CentsToCurrencyPipe} from "../_pipes/centsToCurrency.pipe";
import {ConcatPipe} from "../_pipes/concat.pipe";

@NgModule({
  declarations: [
    SimpleDialogComponent,
    PriceTagComponent,
    PriceTagBannerComponent,
    LibSharingConfigComponent,
    LibRolesDescriptionComponent,
    SafeUrlPipe,
    CapitalizePipe,
    CentsToCurrencyPipe,
    ConcatPipe
  ],
  entryComponents: [
    SimpleDialogComponent
  ],
  imports: [
    CommonModule,
    ResponsiveModule,
    FlexLayoutModule,
    MaterialElementsModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    SimpleDialogComponent,
    PriceTagComponent,
    PriceTagBannerComponent,
    SafeUrlPipe,
    CapitalizePipe,
    CentsToCurrencyPipe,
    ConcatPipe,
    LibSharingConfigComponent,
    LibRolesDescriptionComponent
  ]
})
export class SharedModule {

  static forRoot() {
    return {
      ngModule: SharedModule,
      providers: [
        ProfileService,
        LibraryMetadataService,
        ChargifyTokenService
      ]
    };
  }
}
