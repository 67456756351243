import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {LibraryCategoriesData} from "../_model/library-categories-data.model";
import {LibrarySharingSettings} from "../_model/library-sharing-settings-data.model";
import {PluginsData} from "../_model/plugins-data.model";
import {IntegrationDataRequest} from "../_model/integration-data-request.model";

@Injectable({
  providedIn: 'root'
})
export class LibraryService {

  private readonly URL: string = '/api/libraries';

  constructor(private httpClient: HttpClient) {
  }

  get(id: string): Observable<any> {
    return this.httpClient.get(this.URL + '/' + id);
  }

  getByCatalogItemId(id: string): Observable<any> {
    return this.httpClient.get(this.URL + '/catalogItem/' + id);
  }

  fetchForIntegration(data: IntegrationDataRequest): Observable<any> {
    return this.httpClient.post(this.URL + '/integration', data);
  }

  getLink(id: string): Observable<any> {
    return this.httpClient.get(this.URL + '/' + id + '/link');
  }

  patch(id: string, data: any) {
    return this.httpClient.patch(this.URL + '/' + id, data);
  }

  getCategories(id: string): Observable<LibraryCategoriesData> {
    return this.httpClient.get<LibraryCategoriesData>(this.URL + '/' + id + '/categories');
  }

  saveCategories(id: string, data: LibraryCategoriesData): Observable<any> {
    return this.httpClient.post<any>(this.URL + '/' + id + '/categories', data);
  }

  getSharingSettings(id: string): Observable<LibrarySharingSettings> {
    return this.httpClient.get<LibrarySharingSettings>(this.URL + '/' + id + '/sharing-settings');
  }

  saveSharingSettings(id: string, data: LibrarySharingSettings): Observable<any> {
    return this.httpClient.post<any>(this.URL + '/' + id + '/sharing-settings', data);
  }

  getPluginsData(id: string): Observable<PluginsData> {
    return this.httpClient.get<PluginsData>(this.URL + '/' + id + '/plugins');
  }

}
