import { Injectable } from '@angular/core';

@Injectable()
export class RedirectHelperService {
  private _catalogItemId = "";
  private _url = "";
  private _isTrial = false;

  get url(): string {
    return this._url;
  }

  set url(value: string) {
    this._url = value;
  }

  get isTrial(): boolean {
    return this._isTrial;
  }

  set isTrial(value: boolean) {
    this._isTrial = value;
  }

  get catalogItemId(): string {
    return this._catalogItemId;
  }

  set catalogItemId(value: string) {
    this._catalogItemId = value;
  }

  constructor() { }

}
