import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {IntegrationDataRequest} from "../_model/integration-data-request.model";

@Injectable({
  providedIn: 'root'
})
export class IntegrationService {

  private readonly URL: string = '/api/integration';

  constructor(private httpClient: HttpClient) {
  }

  get(data: IntegrationDataRequest): Observable<any> {
    return this.httpClient.post(this.URL, data);
  }

}
