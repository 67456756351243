import { ChangeDetectorRef, EventEmitter, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroupDirective, Validators } from '@angular/forms';
import { ProfileService } from '../../_services/profile.service';
import { CustomValidators } from "../../validators/custom-validators";
var LibSharingConfigComponent = /** @class */ (function () {
    function LibSharingConfigComponent(formGroupDirective, cdr, fb, profileService) {
        this.formGroupDirective = formGroupDirective;
        this.cdr = cdr;
        this.fb = fb;
        this.profileService = profileService;
        this.libraryRoles = [];
        this.userNrChange = new EventEmitter();
        this._sharingData = this.fb.array([]);
    }
    Object.defineProperty(LibSharingConfigComponent.prototype, "sharingData", {
        get: function () {
            return this._sharingData;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LibSharingConfigComponent.prototype, "loggedUser", {
        get: function () {
            return this._loggedUser;
        },
        enumerable: true,
        configurable: true
    });
    LibSharingConfigComponent.prototype.ngOnInit = function () {
        var _this = this;
        var parentForm = this.formGroupDirective.form;
        parentForm.addControl('sharingData', this._sharingData);
        this.profileService.getObservable().subscribe(function (data) {
            if (data) {
                _this._loggedUser = data.email;
            }
        });
    };
    LibSharingConfigComponent.prototype.clearData = function () {
        if (this._sharingData) {
            var parentForm = this.formGroupDirective.form;
            parentForm.removeControl('sharingData');
            this._sharingData = this.fb.array([]);
            parentForm.addControl('sharingData', this._sharingData);
            this.cdr.detectChanges();
        }
    };
    LibSharingConfigComponent.prototype.addControlToSharingData = function (email, roles) {
        if (email === void 0) { email = ''; }
        if (roles === void 0) { roles = email === this._loggedUser ? this.getAllRoleNames() : this.getMandatoryRoleNames(); }
        this._sharingData.push(this.fb.group({
            email: [{ value: email, disabled: !!email },
                Validators.compose([Validators.email, Validators.required, CustomValidators.duplicateItem(this._sharingData)])],
            roles: [roles, Validators.required]
        }));
        this.userNrChange.emit(this._sharingData.length);
        this.cdr.detectChanges();
    };
    LibSharingConfigComponent.prototype.removeControlFromSharingData = function (index) {
        if (this._sharingData.controls.length <= 1) {
            return;
        }
        this._sharingData.removeAt(index);
        this.userNrChange.emit(this._sharingData.length);
        this.cdr.detectChanges();
    };
    LibSharingConfigComponent.prototype.getMandatoryRoleNames = function () {
        var roles = [];
        for (var _i = 0, _a = this.libraryRoles; _i < _a.length; _i++) {
            var role = _a[_i];
            if (role.isMandatory) {
                roles.push(role.name);
            }
        }
        return roles;
    };
    LibSharingConfigComponent.prototype.getAllRoleNames = function () {
        var roles = [];
        for (var _i = 0, _a = this.libraryRoles; _i < _a.length; _i++) {
            var role = _a[_i];
            roles.push(role.name);
        }
        return roles;
    };
    LibSharingConfigComponent.prototype.isRoleSelected = function (index, selectedRole) {
        return (this._sharingData.controls[index].get('roles').value.indexOf(selectedRole.name) > -1);
    };
    LibSharingConfigComponent.prototype.getRoleMetaDataByName = function (name) {
        var libraryRole = this.libraryRoles.filter(function (role) {
            return role.name === name;
        });
        return libraryRole[0];
    };
    /*keeps contributors role selected if at least one isContributor selected */
    LibSharingConfigComponent.prototype.keepContributorsRoleSelected = function (index) {
        var selectedRoles = this._sharingData.controls[index].get('roles').value;
        var isContributorStillExists = false;
        for (var _i = 0, selectedRoles_1 = selectedRoles; _i < selectedRoles_1.length; _i++) {
            var existingRoleName = selectedRoles_1[_i];
            if (this.getRoleMetaDataByName(existingRoleName).isContributor) {
                isContributorStillExists = true;
                break;
            }
        }
        if (isContributorStillExists) {
            selectedRoles.push('Contributors');
            this._sharingData.controls[index].get('roles').patchValue(selectedRoles);
        }
    };
    LibSharingConfigComponent.prototype.checkForIncludedRoles = function (index, selectedRole) {
        var selectedRoles = this._sharingData.controls[index].get('roles').value;
        if (selectedRole.name !== 'Contributors') {
            if (this.isRoleSelected(index, selectedRole)) {
                if (selectedRole.isContributor && !(selectedRoles.indexOf('Contributors') > -1)) {
                    selectedRoles.push('Contributors');
                    this._sharingData.controls[index].get('roles').patchValue(selectedRoles);
                }
            }
            /* Check if deselecting contributor */
        }
        else if (!this.isRoleSelected(index, selectedRole)) {
            this.keepContributorsRoleSelected(index);
        }
    };
    LibSharingConfigComponent.prototype.isLoggedUser = function (user) {
        return user === this._loggedUser;
    };
    return LibSharingConfigComponent;
}());
export { LibSharingConfigComponent };
