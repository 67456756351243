import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Contact} from "../_model/contact.model";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  private readonly URL: string = '/api/contact';

  constructor(private httpClient: HttpClient) {
  }

  getSelf(): Observable<Contact> {
    return this.httpClient.get<Contact>(this.URL + '/self');
  }

}
