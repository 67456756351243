export class CatalogItem {
  id: string;
  description: string;
  type: string;
  libraryTemplateName: string;
  libraryTemplateLogo: string;
  libraryTemplateVideo: string;
  libraryTemplateId: string;
  price: number;
  isTrial: boolean;
  isFree: boolean;
  bulletPoints: string;
  purchaseLink: string;
  trialData: TrialData;
  userByVolumeChargifyComponent: ChargifyComponent;
  landingPageContent: LandingPageContent;
}

export class TrialData {
  timeUnit: string;
  length: number;
}

export class ChargifyComponent {
  id: string;
  unit_name: string;
  prices: Array<PriceBracket>;
}

export class PriceBracket {
  unit_price: number;
  starting_quantity: number;
  ending_quantity: number;
}

export class LandingPageContent {
  subDescription: string;
  welcomeImage: string;
  widgets: Array<GenericPageContainer>;
  detailContainers: Array<DetailPageContainer>;
}

export class GenericPageContainer {
  title: string;
  description: string;
  image: string;
}

export class DetailPageContainer {
  details: Array<GenericPageContainer>;
  image: string;
}
