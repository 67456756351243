import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BuyLibrarySectionComponent} from "./buy-library-section/buy-library-section.component";
import {CatalogSectionComponent} from "./catalog-section/catalog-section.component";
import {CatalogAppComponent} from "./catalog-app.component";
import {CatalogItemComponent} from "./catalog-item/catalog-item.component";

const routes: Routes = [
    {
      path: '',
      component: CatalogAppComponent,
      children: [
        {
          path: 'item/:id',
          component: CatalogItemComponent
        },
        {
          path: 'buy/:id',
          component: BuyLibrarySectionComponent
        },
        {
          path: 'trials',
          component: CatalogSectionComponent,
          data: {
            filterByTrial: true
          }
        },
        {
          path: '',
          component: CatalogSectionComponent
        },
      ]
    },
  ]
;

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CatalogRoutingModule {
}
