import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LibraryMetadataService {


  constructor(private httpClient: HttpClient) {
  }

  getRoles(id: string): Observable<any> {
    return this.httpClient.get(LibraryMetadataService.getUrl(id) + '/roles');
  }

  getRolesFromTemplate(id: string): Observable<any> {
    return this.httpClient.get(LibraryMetadataService.getUrl(id) + '/roles?from=TEMPLATE');
  }

  private static getUrl(id: string) {
    return `/api/libraries/eu.revevol.aodocs-catalog:default:war:1.0-SNAPSHOT/metadata`;
  }
}
