import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  private readonly URL: string = '/data';

  constructor(private httpClient: HttpClient) {
  }

  getCountries(): Observable<any> {
    return this.httpClient.get<any>(this.URL + '/countries.json');
  }

  getStates(code: string): Observable<any> {
    const params = new HttpParams().set('code', code);
    return this.httpClient.get<any>(this.URL + '/subdivisions', {params: params});
  }
}
