import {Component, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material";
import {RedirectHelperService} from "../../_services/redirect-helper.service";
import {environment} from "../../../environments/environment";
import {ActivatedRoute} from "@angular/router";

declare let hbspt: any;

@Component({
  selector: 'app-contact-registration-section',
  templateUrl: './contact-registration-section.component.html',
  styleUrls: ['./contact-registration-section.component.scss']
})
export class ContactRegistrationSectionComponent implements OnInit {
  public isTrial: boolean;
  private url: string;
  private hubspotFormId: string;
  constructor(public dialog: MatDialog,
              private route: ActivatedRoute,
              private redirectHelperService: RedirectHelperService) {
  }

  ngOnInit() {
    this.url = this.redirectHelperService.url;
    this.isTrial = this.route.snapshot.params.modality === 'trial';
    this.hubspotFormId = this.isTrial ? environment.hubSpotTrialFormId : environment.hubSpotFormId;
    hbspt.forms.create({
      portalId: environment.hubSpotPortalId,
      formId: this.hubspotFormId,
      redirectUrl: this.url,
      target: "#registration"
    });
  }
}
