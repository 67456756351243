import {Component, OnInit} from '@angular/core';

@Component({
  templateUrl: './integration.component.html',
  styleUrls: ['./integration.component.scss']
})
export class IntegrationComponent implements OnInit {

  ngOnInit(): void {
  }

}
