import { HttpClient, HttpParams } from "@angular/common/http";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CountryService = /** @class */ (function () {
    function CountryService(httpClient) {
        this.httpClient = httpClient;
        this.URL = '/data';
    }
    CountryService.prototype.getCountries = function () {
        return this.httpClient.get(this.URL + '/countries.json');
    };
    CountryService.prototype.getStates = function (code) {
        var params = new HttpParams().set('code', code);
        return this.httpClient.get(this.URL + '/subdivisions', { params: params });
    };
    CountryService.ngInjectableDef = i0.defineInjectable({ factory: function CountryService_Factory() { return new CountryService(i0.inject(i1.HttpClient)); }, token: CountryService, providedIn: "root" });
    return CountryService;
}());
export { CountryService };
