/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error-section.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "./error-section.component";
var styles_ErrorSectionComponent = [i0.styles];
var RenderType_ErrorSectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorSectionComponent, data: {} });
export { RenderType_ErrorSectionComponent as RenderType_ErrorSectionComponent };
export function View_ErrorSectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "mat-card", [["class", "mat-card"]], null, null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i3.MatCard, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "mat-card-title", [["class", "mat-card-title"]], null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i3.MatCardTitle, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Something is wrong"])), (_l()(), i1.ɵeld(6, 0, null, 0, 5, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i3.MatCardContent, [], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Looks like something is wrong. Please check your connection and refresh the page. "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" If the problem persists, please contact support. "]))], null, null); }
export function View_ErrorSectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-error-section", [], null, null, null, View_ErrorSectionComponent_0, RenderType_ErrorSectionComponent)), i1.ɵdid(1, 114688, null, 0, i4.ErrorSectionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ErrorSectionComponentNgFactory = i1.ɵccf("app-error-section", i4.ErrorSectionComponent, View_ErrorSectionComponent_Host_0, {}, {}, []);
export { ErrorSectionComponentNgFactory as ErrorSectionComponentNgFactory };
