import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BuyLibraryService {

  private readonly URL: string = '/api/buy-library';

  constructor(private httpClient: HttpClient) {
  }

  buy(data: any): Observable<any> {
    return this.httpClient.post(this.URL, data);
  }


}
