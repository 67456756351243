import { HttpClient } from "@angular/common/http";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ChargifyTokenService = /** @class */ (function () {
    function ChargifyTokenService(httpClient) {
        this.httpClient = httpClient;
        this.URL = '/api';
    }
    ChargifyTokenService.prototype.getSecurityToken = function () {
        return this.httpClient.get(this.URL + '/self/chargify-token');
    };
    ChargifyTokenService.ngInjectableDef = i0.defineInjectable({ factory: function ChargifyTokenService_Factory() { return new ChargifyTokenService(i0.inject(i1.HttpClient)); }, token: ChargifyTokenService, providedIn: "root" });
    return ChargifyTokenService;
}());
export { ChargifyTokenService };
