<section [formGroup]="sharingData">
  <div *ngFor="let item of sharingData.controls; let i = index; last as isLast; first as isFirst">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem"
         fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center stretch"
         [formGroupName]="i">

      <mat-icon fxHide.lt-md="">person</mat-icon>

      <mat-form-field fxFlex.gt-sm="30" fxFlex.lt-sm="100">
        <input  type="email" email matInput placeholder="User E-mail" [required]="true" formControlName="email">
        <mat-error *ngIf="sharingData.at(i).get('email').errors?.duplicate">
          Duplicate email address
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex.gt-sm="25" fxFlex.lt-sm="100">
        <mat-label>Roles</mat-label>
        <mat-select [required]="true" [multiple]="true" formControlName="roles">
          <mat-option *ngFor="let role of libraryRoles" [value]="role.name" [disabled]="role.isMandatory"
                      (click)="checkForIncludedRoles(i,role)"
                      style="height: 44px;">
            {{role.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="sharingData.at(i).get('roles').invalid">One role is required</mat-error>
      </mat-form-field>

      <div fxLayout="row" fxFlex.lt-sm="100" fxLayoutAlign.lt-sm="end center">
        <button *ngIf="!isLoggedUser(sharingData.at(i).get('email').value)"
                mat-icon-button aria-label="Remove user" (click)="removeControlFromSharingData(i)">
          <mat-icon>remove</mat-icon>
        </button>
        <button *ngIf="isLast" mat-icon-button aria-label="Add user" (click)="addControlToSharingData()">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
  </div>
</section>
