import { ChangeDetectorRef, ElementRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { PriceBracket } from "../../_model/catalog-item.model";
import { NgxSpinnerService } from "ngx-spinner";
import { CatalogItemService } from "../../_services/catalog-item.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, Validators } from "@angular/forms";
import { BuyLibraryService } from "../../_services/buy-library.service";
import { MatDialog } from "@angular/material";
import { ProfileService } from "../../_services/profile.service";
import { AngularFireDatabase } from "@angular/fire/database";
import { SimpleDialogComponent } from "../../_components/simple-dialog/simple-dialog.component";
import { Location } from "@angular/common";
import { ContactService } from "../../_services/contact.service";
import { environment } from "../../../environments/environment";
import { LibSharingConfigComponent } from "../../_components/lib-sharing-config/lib-sharing-config.component";
import { LibraryMetadataService } from "../../_services/library-metadata.service";
import { ChargifyTokenService } from "../../_services/chargify-token.service";
import { retry } from "rxjs/operators";
import { CountryService } from "../../_services/country.service";
import { RedirectHelperService } from '../../_services/redirect-helper.service';
var BuyLibrarySectionComponent = /** @class */ (function () {
    function BuyLibrarySectionComponent(spinner, catalogItemService, route, buyLibraryService, fb, dialog, profileService, contactService, libraryMetadataService, chargifyTokenService, countryService, location, cdr, db, router, redirectHelperService) {
        this.spinner = spinner;
        this.catalogItemService = catalogItemService;
        this.route = route;
        this.buyLibraryService = buyLibraryService;
        this.fb = fb;
        this.dialog = dialog;
        this.profileService = profileService;
        this.contactService = contactService;
        this.libraryMetadataService = libraryMetadataService;
        this.chargifyTokenService = chargifyTokenService;
        this.countryService = countryService;
        this.location = location;
        this.cdr = cdr;
        this.db = db;
        this.router = router;
        this.redirectHelperService = redirectHelperService;
        this.termsAccepted = false;
        this.chargifyToken = '';
        this.initDone = false;
        this.usersPurchased = 1;
        this.buyerData = this.fb.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ["", [Validators.required, Validators.email]],
            organization: [''],
            phone: ['', Validators.pattern('([(+]*[0-9]+[()+. -]*)')],
        });
        this.paymentData = this.fb.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            billingZipCode: [''],
            billingState: [''],
            billingCountry: [''],
            billingCity: [''],
            billingAddress: [''],
            nrUsers: [1, Validators.min(1)]
        });
        this.librarySettingsData = this.fb.group({
            cloneWithDocumentsChecked: [false, Validators.required]
        });
        this._chargifyIframeLoaded = false;
        this._countries = [];
        this._states = [];
        this._loadingStates = false;
        this.finalPrice = 0;
        this._recaptchaSiteKey = environment.recaptchaSiteKey;
        this._startButtonClicked = false;
        this._sharingSettingsUsers = [];
        this._data = this.fb.group({});
        this._purchaseCompleted = false;
        this._purchase = null;
        this._step = 0;
        this._libraryRoles = new Array();
    }
    Object.defineProperty(BuyLibrarySectionComponent.prototype, "recaptchaSiteKey", {
        get: function () {
            return this._recaptchaSiteKey;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BuyLibrarySectionComponent.prototype, "startButtonClicked", {
        get: function () {
            return this._startButtonClicked;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BuyLibrarySectionComponent.prototype, "loadingStates", {
        get: function () {
            return this._loadingStates;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BuyLibrarySectionComponent.prototype, "data", {
        get: function () {
            return this._data;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BuyLibrarySectionComponent.prototype, "purchaseCompleted", {
        get: function () {
            return this._purchaseCompleted;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BuyLibrarySectionComponent.prototype, "purchase", {
        get: function () {
            return this._purchase;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BuyLibrarySectionComponent.prototype, "catalogItem", {
        get: function () {
            return this._catalogItem;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BuyLibrarySectionComponent.prototype, "step", {
        get: function () {
            return this._step;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BuyLibrarySectionComponent.prototype, "libraryRoles", {
        get: function () {
            return this._libraryRoles;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BuyLibrarySectionComponent.prototype, "states", {
        get: function () {
            return this._states;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BuyLibrarySectionComponent.prototype, "countries", {
        get: function () {
            return this._countries;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BuyLibrarySectionComponent.prototype, "chargifyIframeLoaded", {
        get: function () {
            return this._chargifyIframeLoaded;
        },
        enumerable: true,
        configurable: true
    });
    BuyLibrarySectionComponent.prototype.ngOnInit = function () {
        var _this = this;
        var id = this.route.snapshot.params.id;
        this.redirectHelperService.catalogItemId = id;
        this.spinner.show();
        this.catalogItemService.getItem(id).subscribe(function (res) {
            _this.spinner.hide();
            if (res.purchaseLink) {
                location.href = res.purchaseLink;
            }
            else {
                _this.loadItem(id);
                _this.setBuyerForValueFromContact();
            }
        }, function (err) {
            console.error("error: ", err);
            _this.spinner.hide();
        });
        this.paymentData.get('nrUsers').valueChanges.subscribe(function (nrUsers) {
            if (nrUsers >= _this.libSharingConfig.sharingData.length) {
                var comp = _this.catalogItem.userByVolumeChargifyComponent;
                _this.usersPurchased = nrUsers;
                var rightPriceBracket = new PriceBracket();
                for (var _i = 0, _a = comp.prices; _i < _a.length; _i++) {
                    var priceBracket = _a[_i];
                    if (nrUsers >= priceBracket.starting_quantity && nrUsers <= priceBracket.ending_quantity) {
                        rightPriceBracket = priceBracket;
                        break;
                    }
                }
                _this.finalPrice = Number((rightPriceBracket.unit_price * nrUsers).toFixed(2));
            }
            else {
                _this.paymentData.get('nrUsers').setValue(_this.libSharingConfig.sharingData.length);
            }
        });
    };
    BuyLibrarySectionComponent.prototype.buildForm = function () {
        var _this = this;
        this._data.addControl('buyerData', this.buyerData);
        this._data.addControl('librarySettingsData', this.librarySettingsData);
        if (!this._catalogItem.isFree) {
            this._data.addControl('paymentData', this.paymentData);
        }
        // sharingData will be build and handled by a child component
        this.initDone = true;
        setTimeout(function () {
            _this.initEmailFormControl();
        }, 0); // I swear I saw this in the documentation. Waits only one tick - @ViewChild fault.
    };
    BuyLibrarySectionComponent.prototype.setStep = function (number) {
        this._step = number;
    };
    BuyLibrarySectionComponent.prototype.onSubmit = function (recaptchaResponse) {
        this.spinner.show();
        this._startButtonClicked = false;
        this.chargify ? this.sendBuyLibraryRequestWithChargify(recaptchaResponse) : this.sendBuyLibraryRequest(recaptchaResponse);
    };
    BuyLibrarySectionComponent.prototype.sendBuyLibraryRequestWithChargify = function (recaptchaResponse) {
        var _this = this;
        this.chargify.token(this.chargifyForm.nativeElement, function (token) {
            console.log('chargify token SUCCESS - token: ', token);
            _this.chargifyToken = token;
            _this.sendBuyLibraryRequest(recaptchaResponse);
        }, function (error) {
            console.log('chargify token ERROR - err: ', error);
            _this.spinner.hide();
            if (error && error.message) {
                _this.showErrorMessageDialog('There was a problem validating the credit card data: ' + error.message);
            }
            if (error && error.status < 500) {
                _this.showErrorMessageDialog('There was a problem validating your credit card. Please ensure the data is valid and that your card is still active.');
            }
            if (error && error.status >= 500) {
                _this.showErrorMessageDialog('Error while validating your credit card. Check your connection and contact support.');
            }
        });
    };
    BuyLibrarySectionComponent.prototype.sendBuyLibraryRequest = function (recaptchaResponse) {
        var _this = this;
        var data = this.getDataToSend(recaptchaResponse);
        this.buyLibraryService.buy(data).subscribe(function (res) {
            _this.spinner.hide();
            _this.openPurchaseCompletedDialog();
            _this.showRecapView(res);
        }, function (err) {
            console.error("error: ", err);
            _this.spinner.hide();
        });
    };
    BuyLibrarySectionComponent.prototype.showErrorMessageDialog = function (message) {
        this.dialog.open(SimpleDialogComponent, {
            width: '450px',
            data: {
                title: 'Something is wrong',
                message: message
            }
        });
    };
    BuyLibrarySectionComponent.prototype.onLoadCaptcha = function () {
        this._startButtonClicked = true;
    };
    BuyLibrarySectionComponent.prototype.isAppInStoreMode = function () {
        return environment.mode ? environment.mode.toUpperCase() === "STORE" : null;
    };
    BuyLibrarySectionComponent.prototype.isAppInTrainingMode = function () {
        return environment.mode ? environment.mode.toUpperCase() === "TRAINING" : null;
    };
    BuyLibrarySectionComponent.prototype.clearPurchaseData = function () {
        var _this = this;
        this._purchase = null;
        this._purchaseCompleted = false;
        setTimeout(function () {
            _this.initEmailFormControl();
        }, 0); // I swear I saw this in the documentation. Waits only one tick - @ViewChild fault.
    };
    BuyLibrarySectionComponent.prototype.goBackToCatalog = function () {
        this.router.navigate(['/catalog']);
    };
    BuyLibrarySectionComponent.prototype.ngOnChanges = function () {
        if (this.chargify) {
            this.chargify.load({ type: 'card' });
            this.chargifyToken = '';
            console.debug("Chargify re-configured");
        }
    };
    BuyLibrarySectionComponent.prototype.ngOnDestroy = function () {
        if (this.chargify) {
            this.chargify.unload();
        }
    };
    BuyLibrarySectionComponent.prototype.setBuyerForValueFromContact = function () {
        var _this = this;
        this.contactService.getSelf().subscribe(function (contact) {
            console.info("contact data", contact);
            if (contact) {
                _this.buyerData.get("phone").setValue(contact.phoneNumber);
                _this.buyerData.get("firstName").setValue(contact.firstName);
                _this.buyerData.get("lastName").setValue(contact.lastName);
                _this.buyerData.get("organization").setValue(contact.organization);
            }
        });
    };
    BuyLibrarySectionComponent.prototype.initEmailFormControl = function () {
        var _this = this;
        this.profileService.getObservable().subscribe(function (data) {
            console.info("data is", data);
            if (data) {
                _this.buyerData.get("email").setValue(data.email);
                _this.buyerData.get("email").disable();
                _this.libSharingConfig.addControlToSharingData(data.email);
                if (_this._sharingSettingsUsers.length > 0) {
                    for (var _i = 0, _a = _this._sharingSettingsUsers; _i < _a.length; _i++) {
                        var userEmail = _a[_i];
                        _this.libSharingConfig.addControlToSharingData(userEmail);
                    }
                    _this._sharingSettingsUsers = [];
                }
            }
            else {
                console.warn('could not retrieve user email');
                _this.libSharingConfig.addControlToSharingData();
            }
        });
    };
    BuyLibrarySectionComponent.prototype.showRecapView = function (res) {
        var _this = this;
        this._purchaseCompleted = true;
        var purchaseId = res.purchaseId;
        var userId = this.profileService.loggedUser.userId;
        var purchasesRef = this.db.object(userId + '/purchases/' + purchaseId);
        purchasesRef.valueChanges().subscribe(function (data) {
            if (!data) {
                return;
            }
            console.info(data);
            _this._purchase = data;
        });
    };
    BuyLibrarySectionComponent.prototype.openPurchaseCompletedDialog = function () {
        this.dialog.open(SimpleDialogComponent, {
            width: '450px',
            data: {
                title: 'We are almost there',
                message: 'We are reviewing your data and creating your library. You\'ll receive an email as soon as the process ends.'
            }
        });
    };
    BuyLibrarySectionComponent.prototype.getDataToSend = function (recaptchaResponse) {
        this.setSharingSettingsData();
        var data = this._data.getRawValue();
        data.catalogItemId = this.catalogItem.id;
        data.userId = this.profileService.loggedUser.userId;
        data.usersPurchased = this.usersPurchased;
        data.isTrial = this._isTrial;
        data.recaptchaResponse = recaptchaResponse;
        if (this.chargifyToken && this.chargifyToken.length) {
            data.paymentData.chargifyToken = this.chargifyToken;
        }
        console.info("sending ", data);
        return data;
    };
    BuyLibrarySectionComponent.prototype.setSharingSettingsData = function () {
        var arrayControl = this._data.get('sharingData').value;
        for (var _i = 0, arrayControl_1 = arrayControl; _i < arrayControl_1.length; _i++) {
            var formGroup = arrayControl_1[_i];
            if (formGroup.email && this.profileService.loggedUser.email !== formGroup.email) {
                this._sharingSettingsUsers.push(formGroup.email);
            }
        }
    };
    BuyLibrarySectionComponent.prototype.loadItem = function (id) {
        var _this = this;
        this.spinner.show();
        this.catalogItemService.getItem(id).subscribe(function (item) {
            _this._catalogItem = item;
            _this.setupLibraryRoles();
        }, function (err) {
            _this.spinner.hide();
            console.log("Remote error: ", err);
        });
    };
    BuyLibrarySectionComponent.prototype.billingCountryUpdated = function (event) {
        var _this = this;
        if (event && event.value) {
            var code_1 = event.value;
            this._loadingStates = true;
            this.countryService.getStates(code_1)
                .pipe(retry(5))
                .subscribe(function (data) {
                code_1 = code_1.toUpperCase();
                _this._states = [];
                var states = data[code_1];
                for (var i = 0; i < states.length; i++) {
                    _this._states.push({
                        Name: states[i][0],
                        Code: states[i][1],
                    });
                }
                _this._loadingStates = false;
            }, function (err) {
                console.error("error while fetching subdivisions", err);
            });
        }
    };
    BuyLibrarySectionComponent.prototype.setupChargifyForCreditCard = function () {
        var _this = this;
        this.countryService.getCountries()
            .pipe(retry(5))
            .subscribe(function (data) {
            _this._countries = data;
        }, function (err) {
            console.error("error while fetching countries", err);
        });
        if (!this.chargify) {
            this._chargifyIframeLoaded = false;
            this.chargifyTokenService.getSecurityToken()
                .pipe(retry(5))
                .subscribe(function (securityToken) {
                _this.chargify = new Chargify();
                _this.chargify.load({
                    securityToken: securityToken,
                    publicKey: environment.chargify.publicKey,
                    type: 'card',
                    serverHost: environment.chargify.host,
                    fields: {
                        number: {
                            selector: '#chargify-number',
                            label: 'Number',
                            required: true
                        },
                        month: {
                            selector: '#chargify-month',
                            label: 'Month',
                            required: true,
                        },
                        year: {
                            selector: '#chargify-year',
                            label: 'Year',
                            required: true
                        },
                        cvv: {
                            selector: '#chargify-cvv',
                            label: 'CVV code',
                            required: false
                        }
                    }
                });
                console.debug("Chargify configured");
                _this._chargifyIframeLoaded = true;
            }, function (err) {
                console.error("There was a problem in getting the chargify security token", err);
            });
        }
    };
    BuyLibrarySectionComponent.prototype.setupLibraryRoles = function () {
        var _this = this;
        this.libraryMetadataService.getRoles(this._catalogItem.libraryTemplateId).subscribe(function (roles) {
            _this._libraryRoles = roles;
            _this.buildForm();
            _this.spinner.hide();
        }, function (err) {
            console.warn("error while getting roles from metadata", err);
        });
    };
    BuyLibrarySectionComponent.prototype.updateNrUsers = function (nrUsers) {
        if (nrUsers >= this.libSharingConfig.sharingData.length) {
            this.paymentData.get('nrUsers').setValue(nrUsers);
            this.usersPurchased = nrUsers;
            var comp = this.catalogItem.userByVolumeChargifyComponent;
            var rightPriceBracket = new PriceBracket();
            for (var _i = 0, _a = comp.prices; _i < _a.length; _i++) {
                var priceBracket = _a[_i];
                if (nrUsers >= priceBracket.starting_quantity && nrUsers <= priceBracket.ending_quantity) {
                    rightPriceBracket = priceBracket;
                    break;
                }
            }
            this.finalPrice = Number((rightPriceBracket.unit_price * nrUsers).toFixed(2));
        }
        else {
            this.paymentData.get('nrUsers').setValue(this.libSharingConfig.sharingData.length);
        }
    };
    Object.defineProperty(BuyLibrarySectionComponent.prototype, "minNbUsers", {
        get: function () {
            return this._data.get('sharingData').value.length;
        },
        enumerable: true,
        configurable: true
    });
    return BuyLibrarySectionComponent;
}());
export { BuyLibrarySectionComponent };
