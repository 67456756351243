import { HttpClient } from "@angular/common/http";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var IntegrationService = /** @class */ (function () {
    function IntegrationService(httpClient) {
        this.httpClient = httpClient;
        this.URL = '/api/integration';
    }
    IntegrationService.prototype.get = function (data) {
        return this.httpClient.post(this.URL, data);
    };
    IntegrationService.ngInjectableDef = i0.defineInjectable({ factory: function IntegrationService_Factory() { return new IntegrationService(i0.inject(i1.HttpClient)); }, token: IntegrationService, providedIn: "root" });
    return IntegrationService;
}());
export { IntegrationService };
