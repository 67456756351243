import {Component, OnDestroy, OnInit} from '@angular/core';
import {CatalogItemService} from "../_services/catalog-item.service";
import {CatalogItem, GenericPageContainer} from "../_model/catalog-item.model";
import {NgxSpinnerService} from "ngx-spinner";
import {ActivatedRoute, Router} from "@angular/router";
import {LibraryService} from "../_services/library.service";
import {RedirectHelperService} from "../_services/redirect-helper.service";

@Component({
  selector: 'app-marketplace-use-case',
  templateUrl: './marketplace-use-case.component.html',
  styleUrls: ['./marketplace-use-case.component.scss']
})
export class MarketplaceUseCaseComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private libraryService: LibraryService,
    private catalogItemService: CatalogItemService,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private redirectHelperService: RedirectHelperService) {
  }

  private _useCaseData: CatalogItem = new CatalogItem();
  private _useCaseId: string;

  ngOnInit() {
    const domain = window.location.hostname;
    this._useCaseId = domain.split('.')[0];
    if (this._useCaseId === 'localhost') {
      this._useCaseId = 'contracts';
    }
    if (this._useCaseId) {
      this.libraryService.getByCatalogItemId(this._useCaseId).subscribe(data => {
        if (data) {
          location.href = data;
        } else {
          this.getUseCaseWelcomePage(this._useCaseId);
        }
      }, err => {
        console.log("Remote error: ", err);
      });
    }
  }


  private getUseCaseWelcomePage(id: string) {
    this.spinner.show();
    this.catalogItemService.getItem(id).subscribe(item => {
      if (item) {
        this._useCaseData = item;
      } else {
        this._useCaseData = null;
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      console.log("Remote error: ", err);
    });
  }


  goToNext() {
    const modality = "trial";
    this.redirectHelperService.url = '/catalog/buy/' + this._useCaseId;
    this.redirectHelperService.catalogItemId = this._useCaseId;
    this.router.navigate(['/contactRegistration/' + modality]);
  }

  contactUs() {
    location.href = "https://3821401.hs-sites.com/contact-us?hsCtaTracking=e5bc1816-1d78-420c-8f19-1afe11f19674%7Ccef76f4c-c048-47be-be8f-56b838dd2cba";
  }

  get useCaseData() {
    return this._useCaseData;
  }

  ngOnDestroy(): void {
  }

}
