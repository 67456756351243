import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ERRORS} from "../_costants/errors";
import {SimpleDialogComponent} from "../_components/simple-dialog/simple-dialog.component";
import {MatDialog} from "@angular/material";
import {Injectable} from "@angular/core";
import {AppError} from "../_model/errors/app-error.model";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private dialog: MatDialog) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((errorData: HttpErrorResponse) => {
          let errorToThrow: any = '';
          if (errorData.error instanceof ErrorEvent) {
            // client-side error
            errorToThrow = new AppError(0, errorData.error ? errorData.error.message : '');
          } else {
            // server-side error
            const apiData: AppError = errorData.error;
            if (apiData) {
              errorToThrow = apiData;
              if (request.method == 'GET') {
                // do nothing
              } else {
                let msg = ERRORS.GENERIC_MSG;
                if (errorData.status < 500) {
                  msg = apiData.message;
                }
                this.dialog.open(SimpleDialogComponent, {
                  width: '450px',
                  data: {
                    title: ERRORS.GENERIC_TITLE,
                    message: msg || ERRORS.GENERIC_MSG
                  }
                });
              }
            }
          }
          return throwError(errorToThrow);
        })
      )
  }
}
