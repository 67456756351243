<footer class="footer" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxLayoutAlign="space-evenly center">
    <div class="footer-top" fxLayout="row" fxLayoutAlign="space-evenly center">
      <div>
        <img src="../../../assets/compliant_@1x.png">
      </div>
      <div fxLayout="column" fxLayoutAlign="center center">
        <p class="description">Recommended for G Suite</p>
        <img src="../../../assets/google_@1x.png">
      </div>
      <div fxLayout="row" fxLayoutAlign="space-evenly center">
        <a href="https://twitter.com/aodocs" target="_blank">
          <div class="social-icon-container bottom-link" fxLayout="row" fxLayoutAlign="center center">
            <img class="social-icon" alt="" src="../../../assets/twitter-white-icon.png"/>
          </div>
        </a>
        <a href="https://www.youtube.com/channel/UC4BSPzADc9QXoh29SAsn3Cg" target="_blank">
          <div class="social-icon-container bottom-link" fxLayout="row" fxLayoutAlign="center center">
            <img class="social-icon" alt="" src="../../../assets/youtube-white-icon.png"/>
          </div>
        </a>
        <a href="https://www.linkedin.com/company/altirnao" target="_blank">
          <div class="social-icon-container bottom-link" fxLayout="row" fxLayoutAlign="center center">
            <img class="social-icon" alt="" src="../../../assets/linkedin-white-icon.png"/>
          </div>
        </a>
      </div>
    </div>
  </div>
  <mat-divider class="bottom-divider"></mat-divider>
  <div class="footer-bottom" fxLayout="row" fxLayoutAlign="space-evenly center">
    <div fxLayout="row">
      <p class="description">Copyright 2012-2019 Altirnao Inc. All rights reserved.</p>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <p class="bottom-link"><a href="https://www.aodocs.com/terms-of-service/" target="_blank">Terms of User</a></p>
      <p class="bottom-link"><a href="https://www.aodocs.com/privacy-policy/" target="_blank">Privacy Policy</a></p>
      <p class="bottom-link"><a
        href="http://patft.uspto.gov/netacgi/nph-Parser?Sect1=PTO2&Sect2=HITOFF&p=1&u=%2Fnetahtml%2FPTO%2Fsearch-bool.html&r=1&f=G&l=50&co1=AND&d=PTXT&s1=9817988.PN.&OS=PN/9817988&RS=PN/9817988"
        target="_blank">U.S Patent 9,817,988</a>
      </p>
    </div>
  </div>
  <img src="../../../assets/Cookie-Control-Toggle-Icon-Blue.png" class="cookies-control-icon"/>
</footer>
