import {AbstractControl, FormArray, ValidationErrors, ValidatorFn} from '@angular/forms';

export class CustomValidators {
  static duplicateItem(form: FormArray): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const emails = [];
      for (let i = 0; i < form.controls.length; i++) {
        emails.push(form.controls[i].get('email').value);
      }
      const test = emails.filter(data => data === control.value && control.value != null);
      return test.length > 1 ? {duplicate: true} : null;
    };
  }
}
