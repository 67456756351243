import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {IntegrationDataRequest} from "../../_model/integration-data-request.model";
import {IntegrationDataResponse} from "../../_model/integration-data-response.model";
import {IntegrationService} from "../../_services/integration.service";

@Component({
  templateUrl: './form-publisher.component.html',
  styleUrls: ['./form-publisher.component.scss']
})
export class FormPublisherComponent implements OnInit {

  private _integrationDataResponse: IntegrationDataResponse;
  private _isFpId = true;

  constructor(private route: ActivatedRoute,
              private integrationService: IntegrationService) {
  }

  ngOnInit(): void {
    const fp_id = this.route.snapshot.params.fp_id;
    if (fp_id) {
      this.fetchLibrary(fp_id);
    } else {
      this._isFpId = false;
    }
  }

  fetchLibrary(fp_id: string) {
    const integrationRequest = new IntegrationDataRequest();
    integrationRequest.formPublisherId = fp_id;
    this.integrationService.get(integrationRequest).subscribe(resp => {
        if (resp) {
          this._integrationDataResponse = resp;
          if (this._integrationDataResponse.link) {
            location.href = this._integrationDataResponse.link;
          } else if (this._integrationDataResponse.inProgress) {
            setTimeout(() => {
              location.reload();
            }, 15000);
          }
        }
      },
      err => {
        console.error("error", err);
      });
  }

  get integrationDataResponse() {
    return this._integrationDataResponse;
  }

  get fpIdExists() {
    return this._isFpId;
  }
}
