import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-error-section',
  templateUrl: './error-section.component.html',
  styleUrls: ['./error-section.component.scss']
})
export class ErrorSectionComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
    setTimeout(() => {
      window.location.href = '/';
    }, 5000)
  }

}
