import {Component, OnInit} from '@angular/core';
import {ProfileService} from "../../_services/profile.service";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {RedirectHelperService} from "../../_services/redirect-helper.service";


@Component({
  selector: 'app-simple-toolbar',
  templateUrl: './simple-toolbar.component.html',
  styleUrls: ['./simple-toolbar.component.scss']
})
export class SimpleToolbarComponent implements OnInit {
  constructor(private profileService: ProfileService,
              private redirectHelperService: RedirectHelperService,
              private router: Router) {
  }

  private _loggedUserEmail: string;

  get loggedUserEmail(): string {
    return this._loggedUserEmail;
  }

  private _showAdminPanel: boolean = false;

  get showAdminPanel(): boolean {
    return this._showAdminPanel;
  }

  ngOnInit(): void {
    this.profileService.getObservable().subscribe(data => {
      if (data) {
        this._loggedUserEmail = data.email;
        this._showAdminPanel = !!data.hasPurchases;
      }
    });
  }

  GoToHomepage(): void {
    if (this.loggedUserEmail == null) {
      this.router.navigate(['/public-catalog']);
    } else {
      this.router.navigate(['/catalog']);
    }
  }

  get homePageTourURL(): string {
    if (this.router.url.includes('/catalog/buy/')) {
      const id = this.redirectHelperService.catalogItemId;
      return environment.productTour.provisioningPage.replace('CATALOG_ITEM', id);
    } else {
      let context;
      if (this.loggedUserEmail == null) {
        context = 'public-catalog';
      } else {
        context = 'catalog';
      }
      return environment.productTour.homePage.replace('VARIABLE_CONTEXT', context);
    }
  }
}
