/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./lib-roles-description.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../_pipes/capitalize.pipe";
import * as i3 from "@angular/common";
import * as i4 from "./lib-roles-description.component";
var styles_LibRolesDescriptionComponent = [i0.styles];
var RenderType_LibRolesDescriptionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LibRolesDescriptionComponent, data: {} });
export { RenderType_LibRolesDescriptionComponent as RenderType_LibRolesDescriptionComponent };
function View_LibRolesDescriptionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "font-light"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Applicable roles"]))], null, null); }
function View_LibRolesDescriptionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵppd(3, 1), (_l()(), i1.ɵted(4, null, [": ", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.name)); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.description; _ck(_v, 4, 0, currVal_1); }); }
export function View_LibRolesDescriptionComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.CapitalizePipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LibRolesDescriptionComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LibRolesDescriptionComponent_2)), i1.ɵdid(5, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.roles.length; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.roles; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_LibRolesDescriptionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-lib-roles-description", [], null, null, null, View_LibRolesDescriptionComponent_0, RenderType_LibRolesDescriptionComponent)), i1.ɵdid(1, 114688, null, 0, i4.LibRolesDescriptionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LibRolesDescriptionComponentNgFactory = i1.ɵccf("app-lib-roles-description", i4.LibRolesDescriptionComponent, View_LibRolesDescriptionComponent_Host_0, { roles: "roles" }, {}, []);
export { LibRolesDescriptionComponentNgFactory as LibRolesDescriptionComponentNgFactory };
