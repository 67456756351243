var RedirectHelperService = /** @class */ (function () {
    function RedirectHelperService() {
        this._catalogItemId = "";
        this._url = "";
        this._isTrial = false;
    }
    Object.defineProperty(RedirectHelperService.prototype, "url", {
        get: function () {
            return this._url;
        },
        set: function (value) {
            this._url = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RedirectHelperService.prototype, "isTrial", {
        get: function () {
            return this._isTrial;
        },
        set: function (value) {
            this._isTrial = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RedirectHelperService.prototype, "catalogItemId", {
        get: function () {
            return this._catalogItemId;
        },
        set: function (value) {
            this._catalogItemId = value;
        },
        enumerable: true,
        configurable: true
    });
    return RedirectHelperService;
}());
export { RedirectHelperService };
