/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./catalog-app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./simple-toolbar/simple-toolbar.component.ngfactory";
import * as i3 from "./simple-toolbar/simple-toolbar.component";
import * as i4 from "../_services/profile.service";
import * as i5 from "../_services/redirect-helper.service";
import * as i6 from "@angular/router";
import * as i7 from "./footer/mat-footer.component.ngfactory";
import * as i8 from "./footer/mat-footer.component";
import * as i9 from "./catalog-app.component";
var styles_CatalogAppComponent = [i0.styles];
var RenderType_CatalogAppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CatalogAppComponent, data: {} });
export { RenderType_CatalogAppComponent as RenderType_CatalogAppComponent };
export function View_CatalogAppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-simple-toolbar", [], null, null, null, i2.View_SimpleToolbarComponent_0, i2.RenderType_SimpleToolbarComponent)), i1.ɵdid(2, 114688, null, 0, i3.SimpleToolbarComponent, [i4.ProfileService, i5.RedirectHelperService, i6.Router], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-mat-footer", [], null, null, null, i7.View_MatFooterComponent_0, i7.RenderType_MatFooterComponent)), i1.ɵdid(7, 114688, null, 0, i8.MatFooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 5, 0); _ck(_v, 7, 0); }, null); }
export function View_CatalogAppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_CatalogAppComponent_0, RenderType_CatalogAppComponent)), i1.ɵdid(1, 114688, null, 0, i9.CatalogAppComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CatalogAppComponentNgFactory = i1.ɵccf("ng-component", i9.CatalogAppComponent, View_CatalogAppComponent_Host_0, {}, {}, []);
export { CatalogAppComponentNgFactory as CatalogAppComponentNgFactory };
