/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-publisher.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i4 from "@angular/material/card";
import * as i5 from "./form-publisher.component";
import * as i6 from "@angular/router";
import * as i7 from "../../_services/integration.service";
var styles_FormPublisherComponent = [i0.styles];
var RenderType_FormPublisherComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormPublisherComponent, data: {} });
export { RenderType_FormPublisherComponent as RenderType_FormPublisherComponent };
function View_FormPublisherComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "section", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" We are creating the structure for you"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["height", "30px"], ["src", "../../../assets/loader.gif"], ["width", "30px"]], null, null, null, null, null))], null, null); }
function View_FormPublisherComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "section", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["There is no folder related to this form. "]))], null, null); }
function View_FormPublisherComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormPublisherComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormPublisherComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.integrationDataResponse.inProgress; _ck(_v, 2, 0, currVal_0); var currVal_1 = (!_co.integrationDataResponse.link && !_co.integrationDataResponse.inProgress); _ck(_v, 4, 0, currVal_1); }, null); }
function View_FormPublisherComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "section", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" No form Publisher ID was found."]))], null, null); }
export function View_FormPublisherComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "container-centered"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "mat-card", [["class", "mat-card"]], null, null, null, i3.View_MatCard_0, i3.RenderType_MatCard)), i1.ɵdid(2, 49152, null, 0, i4.MatCard, [], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_FormPublisherComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_FormPublisherComponent_4)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.integrationDataResponse; _ck(_v, 4, 0, currVal_0); var currVal_1 = !_co.fpIdExists; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_FormPublisherComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_FormPublisherComponent_0, RenderType_FormPublisherComponent)), i1.ɵdid(1, 114688, null, 0, i5.FormPublisherComponent, [i6.ActivatedRoute, i7.IntegrationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormPublisherComponentNgFactory = i1.ɵccf("ng-component", i5.FormPublisherComponent, View_FormPublisherComponent_Host_0, {}, {}, []);
export { FormPublisherComponentNgFactory as FormPublisherComponentNgFactory };
