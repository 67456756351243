import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RedirectHelperService } from '../../_services/redirect-helper.service';
import { LibraryService } from "../../_services/library.service";
var CatalogItemComponent = /** @class */ (function () {
    function CatalogItemComponent(router, libraryService, route, redirectHelperService) {
        this.router = router;
        this.libraryService = libraryService;
        this.route = route;
        this.redirectHelperService = redirectHelperService;
    }
    Object.defineProperty(CatalogItemComponent.prototype, "redirectToHubspotForm", {
        get: function () {
            return this._redirectToHubspotForm;
        },
        enumerable: true,
        configurable: true
    });
    CatalogItemComponent.prototype.ngOnInit = function () {
        var _this = this;
        var catalogItemId = this.route.snapshot.params.id;
        if (catalogItemId) {
            this.libraryService.getByCatalogItemId(catalogItemId).subscribe(function (data) {
                if (data) {
                    location.href = data;
                }
                else {
                    var modality = "trial";
                    _this.saveRedirectUrl(catalogItemId);
                    _this.router.navigate(['/contactRegistration/' + modality]);
                }
            }, function (err) {
                console.log("Remote error: ", err);
            });
        }
    };
    CatalogItemComponent.prototype.saveRedirectUrl = function (itemId) {
        this.redirectHelperService.url = '/catalog/buy/' + itemId;
        this.redirectHelperService.catalogItemId = itemId;
    };
    return CatalogItemComponent;
}());
export { CatalogItemComponent };
