import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ControlContainer, FormArray, FormBuilder, FormGroupDirective, Validators} from '@angular/forms';
import {RoleMetadata} from '../../_model/library-metadata.model';
import {ProfileService} from '../../_services/profile.service';
import {CustomValidators} from "../../validators/custom-validators";

@Component({
  selector: 'app-lib-sharing-config',
  templateUrl: './lib-sharing-config.component.html',
  styleUrls: ['./lib-sharing-config.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: FormGroupDirective}]
})
export class LibSharingConfigComponent implements OnInit {
  @Input()
  libraryRoles: Array<RoleMetadata> = [];
  @Output() userNrChange = new EventEmitter();

  private _sharingData = this.fb.array([]);
  private _loggedUser: string;

  get sharingData(): FormArray {
    return this._sharingData;
  }

  get loggedUser(): string {
    return this._loggedUser;
  }

  constructor(
    private formGroupDirective: FormGroupDirective,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private profileService: ProfileService) {
  }

  ngOnInit() {
    const parentForm = this.formGroupDirective.form;
    parentForm.addControl('sharingData', this._sharingData);
    this.profileService.getObservable().subscribe(data => {
      if (data) {
        this._loggedUser = data.email;
      }
    });
  }

  public clearData() {
    if (this._sharingData) {
      const parentForm = this.formGroupDirective.form;
      parentForm.removeControl('sharingData');
      this._sharingData = this.fb.array([]);
      parentForm.addControl('sharingData', this._sharingData);
      this.cdr.detectChanges();
    }
  }

  public addControlToSharingData(email: string = '', roles: Array<string> = email === this._loggedUser ? this.getAllRoleNames() : this.getMandatoryRoleNames()) {
    this._sharingData.push(
      this.fb.group({
        email: [{value: email, disabled: !!email},
          Validators.compose([Validators.email, Validators.required, CustomValidators.duplicateItem(this._sharingData)])],
        roles: [roles, Validators.required]
      })
    );
    this.userNrChange.emit(this._sharingData.length);
    this.cdr.detectChanges();
  }

  public removeControlFromSharingData(index: number) {
    if (this._sharingData.controls.length <= 1) {
      return;
    }
    this._sharingData.removeAt(index);
    this.userNrChange.emit(this._sharingData.length);
    this.cdr.detectChanges();
  }

  getMandatoryRoleNames() {
    let roles = [];
    for (const role of this.libraryRoles) {
      if (role.isMandatory) {
        roles.push(role.name);
      }
    }
    return roles;
  }

  getAllRoleNames() {
    let roles = [];
    for (const role of this.libraryRoles) {
      roles.push(role.name);
    }
    return roles;
  }

  private isRoleSelected(index: number, selectedRole: RoleMetadata) {
    return (this._sharingData.controls[index].get('roles').value.indexOf(selectedRole.name) > -1);
  }

  private getRoleMetaDataByName(name: string) {
    const libraryRole = this.libraryRoles.filter(role => {
      return role.name === name;
    });
    return libraryRole[0];
  }

  /*keeps contributors role selected if at least one isContributor selected */
  private keepContributorsRoleSelected(index: number) {
    const selectedRoles = this._sharingData.controls[index].get('roles').value;
    let isContributorStillExists = false;
    for (const existingRoleName of selectedRoles) {
      if (this.getRoleMetaDataByName(existingRoleName).isContributor) {
        isContributorStillExists = true;
        break;
      }
    }
    if (isContributorStillExists) {
      selectedRoles.push('Contributors');
      this._sharingData.controls[index].get('roles').patchValue(selectedRoles);
    }
  }

  checkForIncludedRoles(index: number, selectedRole: RoleMetadata) {
    const selectedRoles = this._sharingData.controls[index].get('roles').value;
    if (selectedRole.name !== 'Contributors') {
      if (this.isRoleSelected(index, selectedRole)) {
        if (selectedRole.isContributor && !(selectedRoles.indexOf('Contributors') > -1)) {
          selectedRoles.push('Contributors');
          this._sharingData.controls[index].get('roles').patchValue(selectedRoles);
        }
      }
      /* Check if deselecting contributor */
    } else if (!this.isRoleSelected(index, selectedRole)) {
      this.keepContributorsRoleSelected(index);
    }
  }

  isLoggedUser(user) {
    return user === this._loggedUser;
  }
}
