import {Component, Input, OnInit} from '@angular/core';
import {CatalogItem} from "../../_model/catalog-item.model";
import {ActivatedRoute, Router} from '@angular/router';
import {RedirectHelperService} from '../../_services/redirect-helper.service';
import {LibraryService} from "../../_services/library.service";

@Component({
  selector: 'app-catalog-item',
  templateUrl: './catalog-item.component.html',
  styleUrls: ['./catalog-item.component.scss']
})
export class CatalogItemComponent implements OnInit {
  buyLibraryUrl: string;

  constructor(private router: Router,
              private libraryService: LibraryService,
              private route: ActivatedRoute,
              private redirectHelperService: RedirectHelperService) {
  }

  @Input()
  private _redirectToHubspotForm: true;

  get redirectToHubspotForm(): boolean {
    return this._redirectToHubspotForm;
  }

  ngOnInit() {

    const catalogItemId = this.route.snapshot.params.id;
    if (catalogItemId) {
      this.libraryService.getByCatalogItemId(catalogItemId).subscribe(data => {
        if (data) {
          location.href = data;
        } else {
          const modality = "trial";
          this.saveRedirectUrl(catalogItemId);
          this.router.navigate(['/contactRegistration/' + modality]);
        }
      }, err => {
        console.log("Remote error: ", err);
      });
    }
  }

  saveRedirectUrl(itemId: string) {
    this.redirectHelperService.url = '/catalog/buy/' + itemId;
    this.redirectHelperService.catalogItemId = itemId;
  }
}
