import { OnInit } from '@angular/core';
import { CatalogItem } from "../../_model/catalog-item.model";
import { Router } from '@angular/router';
import { RedirectHelperService } from '../../_services/redirect-helper.service';
var BigCatalogItemComponent = /** @class */ (function () {
    function BigCatalogItemComponent(router, redirectHelperService) {
        this.router = router;
        this.redirectHelperService = redirectHelperService;
        this._bulletPoints = [];
        this._redirectToHubspotForm = false;
    }
    Object.defineProperty(BigCatalogItemComponent.prototype, "bulletPoints", {
        get: function () {
            return this._bulletPoints;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BigCatalogItemComponent.prototype, "redirectToHubspotForm", {
        get: function () {
            return this._redirectToHubspotForm;
        },
        enumerable: true,
        configurable: true
    });
    BigCatalogItemComponent.prototype.ngOnInit = function () {
        if (this.item.bulletPoints) {
            this._bulletPoints = this.item.bulletPoints.split(';');
        }
        if (this.router.url === '/public-catalog') {
            this._redirectToHubspotForm = true;
        }
        this._startingPrice = this.item.userByVolumeChargifyComponent.prices[0].unit_price;
    };
    BigCatalogItemComponent.prototype.moveToNext = function (itemId) {
        if (!this.item.purchaseLink) {
            this.newCustomer(itemId);
        }
        else {
            location.href = this.item.purchaseLink;
        }
    };
    BigCatalogItemComponent.prototype.newCustomer = function (itemId) {
        var modality = "trial";
        this.saveRedirectUrl(itemId);
        if (this.redirectToHubspotForm) {
            this.router.navigate(['/contactRegistration/' + modality]);
        }
        else {
            this.router.navigate(['/catalog/buy/' + itemId]);
        }
    };
    BigCatalogItemComponent.prototype.moveToNextSection = function (itemId, isTrial) {
        itemId = isTrial ? itemId + "-trial" : itemId;
        var modality = isTrial ? "trial" : "purchase";
        this.saveRedirectUrl(itemId);
        if (this.redirectToHubspotForm) {
            this.router.navigate(['/contactRegistration/' + modality]);
        }
        else {
            this.router.navigate(['/catalog/buy/' + itemId]);
        }
    };
    BigCatalogItemComponent.prototype.saveRedirectUrl = function (itemId) {
        this.buyLibraryUrl = '/catalog/buy/' + itemId;
        this.redirectHelperService.url = this.buyLibraryUrl;
        this.redirectHelperService.catalogItemId = itemId;
    };
    Object.defineProperty(BigCatalogItemComponent.prototype, "startingPrice", {
        get: function () {
            return this._startingPrice;
        },
        enumerable: true,
        configurable: true
    });
    return BigCatalogItemComponent;
}());
export { BigCatalogItemComponent };
