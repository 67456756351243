/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./simple-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "@angular/flex-layout/flex";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "./simple-dialog.component";
var styles_SimpleDialogComponent = [i0.styles];
var RenderType_SimpleDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SimpleDialogComponent, data: {} });
export { RenderType_SimpleDialogComponent as RenderType_SimpleDialogComponent };
export function View_SimpleDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 7, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["fxFlex", ""]], null, null, null, null, null)), i1.ɵdid(10, 671744, null, 0, i3.DefaultFlexDirective, [i1.ElementRef, i4.StyleUtils, i4.LAYOUT_CONFIG, i3.FlexStyleBuilder, i4.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 3, "button", [["mat-button", ""], ["tabindex", "0"], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).dialogRef.close(i1.ɵnov(_v, 13).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(12, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(13, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(14, 0, ["", ""]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_3 = ""; _ck(_v, 10, 0, currVal_3); var currVal_7 = true; _ck(_v, 13, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.message; _ck(_v, 6, 0, currVal_2); var currVal_4 = (i1.ɵnov(_v, 12).disabled || null); var currVal_5 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); var currVal_6 = (i1.ɵnov(_v, 13).ariaLabel || null); _ck(_v, 11, 0, currVal_4, currVal_5, currVal_6); var currVal_8 = (_co.confirmButtonLabel || "Ok"); _ck(_v, 14, 0, currVal_8); }); }
export function View_SimpleDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-simple-dialog", [], null, null, null, View_SimpleDialogComponent_0, RenderType_SimpleDialogComponent)), i1.ɵdid(1, 114688, null, 0, i10.SimpleDialogComponent, [i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SimpleDialogComponentNgFactory = i1.ɵccf("app-simple-dialog", i10.SimpleDialogComponent, View_SimpleDialogComponent_Host_0, {}, {}, []);
export { SimpleDialogComponentNgFactory as SimpleDialogComponentNgFactory };
