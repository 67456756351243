// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  mode: 'STORE',
  appURL: 'https://aodocs-catalog-staging.appspot.com/',
  datastudioReport: 'https://datastudio.google.com/embed/reporting/1ZLAY8GK_YhQ62VwdGaD7gpU_20nXODci/page/5iM2',
  recaptchaSiteKey: '6LcEkcEUAAAAABLw2210lgVaeEqZgAh2DMg9q4Rh',
  hubSpotPortalId: '3821401',
  hubSpotFormId: 'b6a502e7-5078-4560-a01c-2a22bbc34ba4',
  hubSpotTrialFormId: '11cd17fc-c237-405c-aec5-4ab6243adaa1',
  firebase: {
    apiKey: 'AIzaSyBTbbDEiAm2z15n1HLjmBi4EuJfEkvEhwo',
    authDomain: 'aodocs-catalog-staging.firebaseapp.com',
    databaseURL: 'https://aodocs-catalog-staging.firebaseio.com',
    projectId: 'aodocs-catalog-staging',
    storageBucket: 'aodocs-catalog-staging.appspot.com',
    messagingSenderId: '605965101684',
    appId: '1:605965101684:web:0dc05ec23d159e3b'
  },
  productTour: {
    homePage: 'https://aodocs-catalog-staging.appspot.com/VARIABLE_CONTEXT?appcue=-LvzxBWRL8tq_zwAHmRE',
    admin: 'https://aodocs-catalog-staging.appspot.com/admin?appcue=-LwOmh4DOzGPhgZE67Qx',
    provisioningPage : 'https://aodocs-catalog-staging.appspot.com/catalog/buy/CATALOG_ITEM?appcue=-Lu2o8-Pu_CfR6ZDzSEu'

  },
  chargify: {
    host: 'https://altirnao-aodocs-dev-clone-clone.chargify.com',
    publicKey: 'chjs_cyqhck42q87d7f7mp4ns6pyn'
  },
  dynamicScriptsToLoad: [
    {name: 'appcues', src: 'https://fast.appcues.com/57699.js'}
  ]
};
