import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material";

@Component({
  selector: 'app-simple-dialog',
  templateUrl: './simple-dialog.component.html',
  styleUrls: ['./simple-dialog.component.scss']
})
export class SimpleDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) data: any) {
    this._message = data.message;
    this._title = data.title;
    this._confirmButtonLabel = data.confirmButtonLabel;
  }

  private _message: string = '';

  get message(): string {
    return this._message;
  }

  private _title: string = '';

  get title(): string {
    return this._title;
  }

  private _confirmButtonLabel: string = '';

  get confirmButtonLabel(): string {
    return this._confirmButtonLabel;
  }

  ngOnInit() {
  }

}
