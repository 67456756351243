import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-mat-footer',
  templateUrl: './mat-footer.component.html',
  styleUrls: ['./mat-footer.component.scss']
})
export class MatFooterComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {
  }

}
