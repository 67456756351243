import { OnDestroy, OnInit } from '@angular/core';
import { CatalogItemService } from "../_services/catalog-item.service";
import { CatalogItem } from "../_model/catalog-item.model";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from "@angular/router";
import { LibraryService } from "../_services/library.service";
import { RedirectHelperService } from "../_services/redirect-helper.service";
var MarketplaceUseCaseComponent = /** @class */ (function () {
    function MarketplaceUseCaseComponent(router, libraryService, catalogItemService, activatedRoute, spinner, redirectHelperService) {
        this.router = router;
        this.libraryService = libraryService;
        this.catalogItemService = catalogItemService;
        this.activatedRoute = activatedRoute;
        this.spinner = spinner;
        this.redirectHelperService = redirectHelperService;
        this._useCaseData = new CatalogItem();
    }
    MarketplaceUseCaseComponent.prototype.ngOnInit = function () {
        var _this = this;
        var domain = window.location.hostname;
        this._useCaseId = domain.split('.')[0];
        if (this._useCaseId === 'localhost') {
            this._useCaseId = 'contracts';
        }
        if (this._useCaseId) {
            this.libraryService.getByCatalogItemId(this._useCaseId).subscribe(function (data) {
                if (data) {
                    location.href = data;
                }
                else {
                    _this.getUseCaseWelcomePage(_this._useCaseId);
                }
            }, function (err) {
                console.log("Remote error: ", err);
            });
        }
    };
    MarketplaceUseCaseComponent.prototype.getUseCaseWelcomePage = function (id) {
        var _this = this;
        this.spinner.show();
        this.catalogItemService.getItem(id).subscribe(function (item) {
            if (item) {
                _this._useCaseData = item;
            }
            else {
                _this._useCaseData = null;
            }
            _this.spinner.hide();
        }, function (err) {
            _this.spinner.hide();
            console.log("Remote error: ", err);
        });
    };
    MarketplaceUseCaseComponent.prototype.goToNext = function () {
        var modality = "trial";
        this.redirectHelperService.url = '/catalog/buy/' + this._useCaseId;
        this.redirectHelperService.catalogItemId = this._useCaseId;
        this.router.navigate(['/contactRegistration/' + modality]);
    };
    MarketplaceUseCaseComponent.prototype.contactUs = function () {
        location.href = "https://3821401.hs-sites.com/contact-us?hsCtaTracking=e5bc1816-1d78-420c-8f19-1afe11f19674%7Ccef76f4c-c048-47be-be8f-56b838dd2cba";
    };
    Object.defineProperty(MarketplaceUseCaseComponent.prototype, "useCaseData", {
        get: function () {
            return this._useCaseData;
        },
        enumerable: true,
        configurable: true
    });
    MarketplaceUseCaseComponent.prototype.ngOnDestroy = function () {
    };
    return MarketplaceUseCaseComponent;
}());
export { MarketplaceUseCaseComponent };
