import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs/internal/Observable";
import {ApiResponse} from "../_model/api-response.model";
import {CatalogItem} from "../_model/catalog-item.model";

@Injectable({
  providedIn: 'root'
})
export class CatalogItemService {

  private readonly URL = '/api/items';
  private readonly PROJECTION_VIEW = 'view';

  constructor(private httpClient: HttpClient) {
  }

  getItems(): Observable<ApiResponse<CatalogItem>> {
    return this.httpClient.get<ApiResponse<CatalogItem>>(this.URL + '/search/published?isPublished=true');
  }

  getItem(id: string): Observable<CatalogItem> {
    const params = new HttpParams().set('projection', this.PROJECTION_VIEW);
    return this.httpClient.get<CatalogItem>(this.URL + '/' + id, {
      params: params
    });
  }
}
