import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {HttpErrorInterceptor} from "../_interceptors/http-error.interceptor";
import {BuyLibraryService} from "../_services/buy-library.service";
import {ResponsiveModule} from "ngx-responsive";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MaterialElementsModule} from "../_modules/material-elements.module";
import {NgxSpinnerModule} from "ngx-spinner";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AngularFireModule} from "@angular/fire";
import {environment} from "../../environments/environment";
import {AngularFireDatabaseModule} from "@angular/fire/database";
import {SharedModule} from "../_modules/shared.module";
import {IntegrationComponent} from "./integration.component";
import {FormPublisherComponent} from "./form-publisher/form-publisher.component";
import {IntegrationRoutingModule} from "./integration-routing.module";
import {LibraryService} from "../_services/library.service";

@NgModule({
  declarations: [
    IntegrationComponent,
    FormPublisherComponent
  ],
  entryComponents: [],
  imports: [
    CommonModule,
    ResponsiveModule,
    FlexLayoutModule,
    MaterialElementsModule,
    NgxSpinnerModule,
    HttpClientModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    SharedModule,
    FormsModule,
    IntegrationRoutingModule
  ],
  exports: [
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    LibraryService
  ]
})
export class IntegrationModule {
}
