import { Routes } from '@angular/router';
import { BuyLibrarySectionComponent } from "./buy-library-section/buy-library-section.component";
import { CatalogSectionComponent } from "./catalog-section/catalog-section.component";
import { CatalogAppComponent } from "./catalog-app.component";
import { CatalogItemComponent } from "./catalog-item/catalog-item.component";
var ɵ0 = {
    filterByTrial: true
};
var routes = [
    {
        path: '',
        component: CatalogAppComponent,
        children: [
            {
                path: 'item/:id',
                component: CatalogItemComponent
            },
            {
                path: 'buy/:id',
                component: BuyLibrarySectionComponent
            },
            {
                path: 'trials',
                component: CatalogSectionComponent,
                data: ɵ0
            },
            {
                path: '',
                component: CatalogSectionComponent
            },
        ]
    },
];
var CatalogRoutingModule = /** @class */ (function () {
    function CatalogRoutingModule() {
    }
    return CatalogRoutingModule;
}());
export { CatalogRoutingModule };
export { ɵ0 };
