import { HttpClient } from "@angular/common/http";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var LibraryMetadataService = /** @class */ (function () {
    function LibraryMetadataService(httpClient) {
        this.httpClient = httpClient;
    }
    LibraryMetadataService.prototype.getRoles = function (id) {
        return this.httpClient.get(LibraryMetadataService.getUrl(id) + '/roles');
    };
    LibraryMetadataService.prototype.getRolesFromTemplate = function (id) {
        return this.httpClient.get(LibraryMetadataService.getUrl(id) + '/roles?from=TEMPLATE');
    };
    LibraryMetadataService.getUrl = function (id) {
        return "/api/libraries/" + id + "/metadata";
    };
    LibraryMetadataService.ngInjectableDef = i0.defineInjectable({ factory: function LibraryMetadataService_Factory() { return new LibraryMetadataService(i0.inject(i1.HttpClient)); }, token: LibraryMetadataService, providedIn: "root" });
    return LibraryMetadataService;
}());
export { LibraryMetadataService };
