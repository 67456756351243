import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {ResponsiveModule} from "ngx-responsive";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MaterialElementsModule} from "./_modules/material-elements.module";
import {NgxSpinnerModule} from "ngx-spinner";
import {HttpClientModule} from "@angular/common/http";
import {AppRoutingModule} from "./app-routing.module";
import {SharedModule} from "./_modules/shared.module";
import {FormsModule} from "@angular/forms";
import {ErrorSectionComponent} from './_components/error-section/error-section.component';
import {ContactRegistrationSectionComponent} from './catalog/registration-form/contact-registration-section.component';
import {CatalogModule} from './catalog/catalog.module';
import {RedirectHelperService} from './_services/redirect-helper.service';
import {ScriptService} from './_services/script.service';
import {IntegrationModule} from "./integration/integration.module";

@NgModule({
  declarations: [
    AppComponent,
    ErrorSectionComponent,
    ContactRegistrationSectionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule.forRoot(),
    MaterialElementsModule,
    ResponsiveModule.forRoot(),
    BrowserAnimationsModule,
    FlexLayoutModule,
    NgxSpinnerModule,
    HttpClientModule,
    FormsModule,
    CatalogModule,
    IntegrationModule
  ],
  providers: [
    RedirectHelperService,
    ScriptService
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
