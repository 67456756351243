import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Subject } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ProfileService = /** @class */ (function () {
    function ProfileService(httpClient) {
        this.httpClient = httpClient;
        this.URL = '/api/profile';
        this._subject = new BehaviorSubject(this._loggedUser);
    }
    Object.defineProperty(ProfileService.prototype, "loggedUser", {
        get: function () {
            return this._loggedUser;
        },
        enumerable: true,
        configurable: true
    });
    ProfileService.prototype.getSelf = function () {
        return this.httpClient.get(this.URL + '/self');
    };
    ProfileService.prototype.getObservable = function () {
        return this._subject.asObservable();
    };
    ProfileService.prototype.initLoggedUser = function () {
        var _this = this;
        var subject = new Subject();
        this.getSelf().subscribe(function (data) {
            _this._loggedUser = data;
            _this._subject.next(data);
            subject.next(true);
        }, function (err) {
            console.info("Couldn't set the logged user", err);
            subject.next(false);
        });
        return subject.asObservable();
    };
    ProfileService.ngInjectableDef = i0.defineInjectable({ factory: function ProfileService_Factory() { return new ProfileService(i0.inject(i1.HttpClient)); }, token: ProfileService, providedIn: "root" });
    return ProfileService;
}());
export { ProfileService };
