<div class="main-section">
  <section>
    <mat-card fxLayout="column" class="ribbon-card">
      <div class="ribbon" *ngIf="ribbonOpen">
        <h3 style="margin-top: 0.5em">Welcome To The AODocs Playground!</h3>
         <section>
          <p style="text-align: center">
            AODocs has been designed to make your life easier - and in our opinion the best way to prove that is to let you experience AODocs for yourself.<br>
            Below you will find a series of AODocs Libraries. Each library is a self-contained environment with everything you need to experience AODocs for a specific solution area such as Quality Management, Contracts Management, and more.<br>
            To try a specific library just click on the blue button - you will go through a simple sign up process and voila - you will have your own AODocs virtual playground for the next 14 days.<br><br>
            So what are you waiting for? Come on in.
          </p>
        </section>
      </div>
      <div fxLayout="column">
        <button mat-button (click)="ribbonOpen = !ribbonOpen">
          <mat-icon>{{ribbonOpen ? 'expand_less' : 'expand_more'}}</mat-icon>
        </button>
      </div>
    </mat-card>
  </section>

  <section class="catalog-items-section" fxLayout="row wrap" fxLayoutAlign="start stretch"
           fxLayout.lt-md="column" fxLayoutAlign.lt-md="center"
           [ngClass.lg]="'section-lg'"
           [ngClass.md]="'section-md'">
    <div *ngFor="let item of catalogItems" fxLayout="row"
         fxLayoutAlign="center stretch" fxFlex="33"
         fxFlex.lt-lg="50" fxFlex.lt-md="100">
      <app-big-catalog-item [item]="item"
                            style="padding: 1em 0"
                            [ngStyle.lt-md]="{'padding': '8px 0'}"></app-big-catalog-item>
    </div>
  </section>
  <div *ngIf="catalogItems" class="bg-triangle"></div>
  <div *ngIf="catalogItems" class="bg-rectangle"></div>
</div>
