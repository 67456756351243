import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {CatalogRoutingModule} from './catalog-routing.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {HttpErrorInterceptor} from "../_interceptors/http-error.interceptor";
import {CatalogItemService} from "../_services/catalog-item.service";
import {BuyLibraryService} from "../_services/buy-library.service";
import {CatalogSectionComponent} from "./catalog-section/catalog-section.component";
import {BuyLibrarySectionComponent} from "./buy-library-section/buy-library-section.component";
import {ResponsiveModule} from "ngx-responsive";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MaterialElementsModule} from "../_modules/material-elements.module";
import {NgxSpinnerModule} from "ngx-spinner";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AngularFireModule} from "@angular/fire";
import {environment} from "../../environments/environment";
import {AngularFireDatabaseModule} from "@angular/fire/database";
import {SharedModule} from "../_modules/shared.module";
import {CatalogAppComponent} from "./catalog-app.component";
import {SimpleToolbarComponent} from "./simple-toolbar/simple-toolbar.component";
import {BigCatalogItemComponent} from './big-catalog-item/big-catalog-item.component';
import {ContactService} from "../_services/contact.service";
import {RecaptchaModule} from 'ng-recaptcha';
import {ScriptService} from '../_services/script.service';
import {MatFooterComponent} from "./footer/mat-footer.component";
import {PriceBracketsComponent} from "../_components/price-brackets/price-brackets.component";
import {MarketplaceUseCaseComponent} from "../marketplace/marketplace-use-case.component";
import {CatalogItemComponent} from "./catalog-item/catalog-item.component";

@NgModule({
  declarations: [
    CatalogAppComponent,
    SimpleToolbarComponent,
    MatFooterComponent,
    PriceBracketsComponent,
    CatalogSectionComponent,
    BuyLibrarySectionComponent,
    BigCatalogItemComponent,
    CatalogItemComponent,
    MarketplaceUseCaseComponent
  ],
  entryComponents: [],
  imports: [
    CommonModule,
    CatalogRoutingModule,
    ResponsiveModule,
    FlexLayoutModule,
    MaterialElementsModule,
    NgxSpinnerModule,
    HttpClientModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    SharedModule,
    FormsModule,
    RecaptchaModule
  ],
  exports: [
    SimpleToolbarComponent,
    PriceBracketsComponent,
    MatFooterComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    CatalogItemService,
    BuyLibraryService,
    ContactService,
    ScriptService
  ]
})
export class CatalogModule {
}
