import {Component, OnInit} from '@angular/core';

@Component({
  templateUrl: './catalog-app.component.html',
  styleUrls: ['./catalog-app.component.scss']
})
export class CatalogAppComponent implements OnInit {

  ngOnInit(): void {
  }

}
