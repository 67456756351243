import {ChangeDetectorRef, Component, ElementRef, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CatalogItem, ChargifyComponent, PriceBracket} from "../../_model/catalog-item.model";
import {NgxSpinnerService} from "ngx-spinner";
import {CatalogItemService} from "../../_services/catalog-item.service";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, Validators} from "@angular/forms";
import {BuyLibraryService} from "../../_services/buy-library.service";
import {MatDialog, MatSelectChange} from "@angular/material";
import {ProfileService} from "../../_services/profile.service";
import {AngularFireDatabase} from "@angular/fire/database";
import {SimpleDialogComponent} from "../../_components/simple-dialog/simple-dialog.component";
import {Location} from "@angular/common";
import {ContactService} from "../../_services/contact.service";
import {environment} from "../../../environments/environment";
import {LibSharingConfigComponent} from "../../_components/lib-sharing-config/lib-sharing-config.component";
import {RoleMetadata} from "../../_model/library-metadata.model";
import {LibraryMetadataService} from "../../_services/library-metadata.service";
import {ChargifyTokenService} from "../../_services/chargify-token.service";
import {retry} from "rxjs/operators";
import {CountryService} from "../../_services/country.service";
import {RedirectHelperService} from '../../_services/redirect-helper.service';

declare var Chargify: any;

@Component({
  selector: 'app-buy-library-section',
  templateUrl: './buy-library-section.component.html',
  styleUrls: ['./buy-library-section.component.scss']
})
export class BuyLibrarySectionComponent implements OnInit, OnDestroy, OnChanges {

  @ViewChild('chargifyForm') chargifyForm: ElementRef;
  public termsAccepted = false;
  private chargifyToken = '';
  initDone = false;
  usersPurchased = 1;
  private chargify: any;
  private buyerData = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: ["", [Validators.required, Validators.email]],
    organization: [''],
    phone: ['', Validators.pattern('([(+]*[0-9]+[()+. -]*)')],
  });
  private paymentData = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    billingZipCode: [''],
    billingState: [''],
    billingCountry: [''],
    billingCity: [''],
    billingAddress: [''],
    nrUsers: [1, Validators.min(1)]
  });
  private librarySettingsData = this.fb.group({
    cloneWithDocumentsChecked: [false, Validators.required]
  });
  @ViewChild(LibSharingConfigComponent)
  private libSharingConfig: LibSharingConfigComponent;
  private _chargifyIframeLoaded: boolean = false;
  private _countries: Array<any> = [];
  private _states: Array<any> = [];
  private _loadingStates: boolean = false;
  private finalPrice = 0;

  private _recaptchaSiteKey = environment.recaptchaSiteKey;
  private _startButtonClicked: boolean = false;

  private _sharingSettingsUsers: Array<string> = [];

  get recaptchaSiteKey(): string {
    return this._recaptchaSiteKey;
  }

  get startButtonClicked(): boolean {
    return this._startButtonClicked;
  }

  constructor(private spinner: NgxSpinnerService,
              private catalogItemService: CatalogItemService,
              private route: ActivatedRoute,
              private buyLibraryService: BuyLibraryService,
              private fb: FormBuilder,
              public dialog: MatDialog,
              private profileService: ProfileService,
              private contactService: ContactService,
              private libraryMetadataService: LibraryMetadataService,
              private chargifyTokenService: ChargifyTokenService,
              private countryService: CountryService,
              private location: Location,
              private cdr: ChangeDetectorRef,
              private db: AngularFireDatabase,
              private router: Router,
              private redirectHelperService: RedirectHelperService) {
  }

  private _data = this.fb.group({});
  private _isTrial: boolean;

  get loadingStates(): boolean {
    return this._loadingStates;
  }

  get data() {
    return this._data;
  }

  private _purchaseCompleted: boolean = false;

  get purchaseCompleted(): boolean {
    return this._purchaseCompleted;
  }

  private _purchase = null;

  get purchase(): any {
    return this._purchase;
  }

  private _catalogItem: CatalogItem;

  get catalogItem(): CatalogItem {
    return this._catalogItem;
  }

  private _step: number = 0;

  get step(): number {
    return this._step;
  }

  private _libraryRoles: Array<RoleMetadata> = new Array<RoleMetadata>();

  get libraryRoles() {
    return this._libraryRoles;
  }

  get states(): Array<any> {
    return this._states;
  }

  get countries(): Array<any> {
    return this._countries;
  }

  get chargifyIframeLoaded(): boolean {
    return this._chargifyIframeLoaded;
  }

  ngOnInit() {
    const id = this.route.snapshot.params.id;
    this.redirectHelperService.catalogItemId = id;
    this.spinner.show();
    this.catalogItemService.getItem(id).subscribe(
      res => {
        this.spinner.hide();
        if (res.purchaseLink) {
          location.href = res.purchaseLink;
        } else {
          this.loadItem(id);
          this.setBuyerForValueFromContact();
        }
      },
      err => {
        console.error("error: ", err);
        this.spinner.hide();
      }
    );
    this.paymentData.get('nrUsers').valueChanges.subscribe(nrUsers => {
      if (nrUsers >= this.libSharingConfig.sharingData.length) {
        const comp: ChargifyComponent = this.catalogItem.userByVolumeChargifyComponent;
        this.usersPurchased = nrUsers;
        let rightPriceBracket: PriceBracket = new PriceBracket();
        for (const priceBracket of comp.prices) {
          if (nrUsers >= priceBracket.starting_quantity && nrUsers <= priceBracket.ending_quantity) {
            rightPriceBracket = priceBracket;
            break;
          }
        }
        this.finalPrice = Number((rightPriceBracket.unit_price * nrUsers).toFixed(2));
      } else {
        this.paymentData.get('nrUsers').setValue(this.libSharingConfig.sharingData.length);
      }
    });

  }

  buildForm() {
    this._data.addControl('buyerData', this.buyerData);
    this._data.addControl('librarySettingsData', this.librarySettingsData);
    if (!this._catalogItem.isFree) {
      this._data.addControl('paymentData', this.paymentData);
    }
    // sharingData will be build and handled by a child component
    this.initDone = true;
    setTimeout(() => {
      this.initEmailFormControl();
    }, 0); // I swear I saw this in the documentation. Waits only one tick - @ViewChild fault.
  }

  setStep(number: number) {
    this._step = number;
  }

  onSubmit(recaptchaResponse: string) {
    this.spinner.show();
    this._startButtonClicked = false;
    this.chargify ? this.sendBuyLibraryRequestWithChargify(recaptchaResponse) : this.sendBuyLibraryRequest(recaptchaResponse);
  }

  private sendBuyLibraryRequestWithChargify(recaptchaResponse: string) {
    this.chargify.token(
      this.chargifyForm.nativeElement,
      (token) => {
        console.log('chargify token SUCCESS - token: ', token);
        this.chargifyToken = token;

        this.sendBuyLibraryRequest(recaptchaResponse);

      },
      (error) => {
        console.log('chargify token ERROR - err: ', error);
        this.spinner.hide();
        if (error && error.message) {
          this.showErrorMessageDialog('There was a problem validating the credit card data: ' + error.message);
        }
        if (error && error.status < 500) {
          this.showErrorMessageDialog('There was a problem validating your credit card. Please ensure the data is valid and that your card is still active.');
        }
        if (error && error.status >= 500) {
          this.showErrorMessageDialog('Error while validating your credit card. Check your connection and contact support.');
        }
      }
    );
  }

  private sendBuyLibraryRequest(recaptchaResponse: string) {
    const data = this.getDataToSend(recaptchaResponse);
    this.buyLibraryService.buy(data).subscribe(
      res => {
        this.spinner.hide();
        this.openPurchaseCompletedDialog();
        this.showRecapView(res);
      },
      err => {
        console.error("error: ", err);
        this.spinner.hide();
      }
    );
  }

  private showErrorMessageDialog(message) {
    this.dialog.open(SimpleDialogComponent, {
      width: '450px',
      data: {
        title: 'Something is wrong',
        message: message
      }
    });
  }

  onLoadCaptcha() {
    this._startButtonClicked = true;
  }

  isAppInStoreMode(): boolean {
    return environment.mode ? environment.mode.toUpperCase() === "STORE" : null;
  }

  isAppInTrainingMode(): boolean {
    return environment.mode ? environment.mode.toUpperCase() === "TRAINING" : null;
  }

  clearPurchaseData() {
    this._purchase = null;
    this._purchaseCompleted = false;
    setTimeout(() => {
      this.initEmailFormControl();
    }, 0); // I swear I saw this in the documentation. Waits only one tick - @ViewChild fault.
  }

  goBackToCatalog() {
    this.router.navigate(['/catalog']);
  }

  ngOnChanges() {
    if (this.chargify) {
      this.chargify.load({type: 'card'});
      this.chargifyToken = '';
      console.debug("Chargify re-configured");
    }
  }

  ngOnDestroy() {
    if (this.chargify) {
      this.chargify.unload();
    }
  }

  private setBuyerForValueFromContact() {
    this.contactService.getSelf().subscribe(contact => {
      console.info("contact data", contact);
      if (contact) {
        this.buyerData.get("phone").setValue(contact.phoneNumber);
        this.buyerData.get("firstName").setValue(contact.firstName);
        this.buyerData.get("lastName").setValue(contact.lastName);
        this.buyerData.get("organization").setValue(contact.organization);
      }
    });
  }

  private initEmailFormControl() {
    this.profileService.getObservable().subscribe(data => {
      console.info("data is", data);
      if (data) {
        this.buyerData.get("email").setValue(data.email);
        this.buyerData.get("email").disable();
        this.libSharingConfig.addControlToSharingData(data.email);
        if (this._sharingSettingsUsers.length > 0) {
          for (const userEmail of this._sharingSettingsUsers) {
            this.libSharingConfig.addControlToSharingData(userEmail);
          }
          this._sharingSettingsUsers = [];
        }
      } else {
        console.warn('could not retrieve user email');
        this.libSharingConfig.addControlToSharingData();
      }
    });
  }

  private showRecapView(res) {
    this._purchaseCompleted = true;
    const purchaseId = res.purchaseId;
    const userId = this.profileService.loggedUser.userId;
    const purchasesRef = this.db.object(userId + '/purchases/' + purchaseId);
    purchasesRef.valueChanges().subscribe((data: any) => {
      if (!data) {
        return;
      }
      console.info(data);
      this._purchase = data;
    });
  }

  private openPurchaseCompletedDialog() {
    this.dialog.open(SimpleDialogComponent, {
      width: '450px',
      data: {
        title: 'We are almost there',
        message: 'We are reviewing your data and creating your library. You\'ll receive an email as soon as the process ends.'
      }
    });
  }

  private getDataToSend(recaptchaResponse: string) {
    this.setSharingSettingsData();
    const data = this._data.getRawValue();
    data.catalogItemId = this.catalogItem.id;
    data.userId = this.profileService.loggedUser.userId;
    data.usersPurchased = this.usersPurchased;
    data.isTrial = this._isTrial;
    data.recaptchaResponse = recaptchaResponse;
    if (this.chargifyToken && this.chargifyToken.length) {
      data.paymentData.chargifyToken = this.chargifyToken;
    }
    console.info("sending ", data);
    return data;
  }

  private setSharingSettingsData() {
    const arrayControl = this._data.get('sharingData').value;
    for (const formGroup of arrayControl) {
      if (formGroup.email && this.profileService.loggedUser.email !== formGroup.email) {
        this._sharingSettingsUsers.push(formGroup.email);
      }
    }
  }

  private loadItem(id) {
    this.spinner.show();
    this.catalogItemService.getItem(id).subscribe(item => {
      this._catalogItem = item;
      this.setupLibraryRoles();
    }, err => {
      this.spinner.hide();
      console.log("Remote error: ", err);
    });
  }

  private billingCountryUpdated(event: MatSelectChange) {
    if (event && event.value) {
      let code: string = event.value;
      this._loadingStates = true;
      this.countryService.getStates(code)
        .pipe(retry(5))
        .subscribe(
          (data) => {
            code = code.toUpperCase();
            this._states = [];
            const states = data[code];
            for (let i = 0; i < states.length; i++) {
              this._states.push({
                Name: states[i][0],
                Code: states[i][1],
              });
            }
            this._loadingStates = false;
          },
          (err) => {
            console.error("error while fetching subdivisions", err);
          }
        );
    }
  }

  private setupChargifyForCreditCard() {
    this.countryService.getCountries()
      .pipe(retry(5))
      .subscribe(
        data => {
          this._countries = data;
        }, err => {
          console.error("error while fetching countries", err);
        }
      );

    if (!this.chargify) {
      this._chargifyIframeLoaded = false;

      this.chargifyTokenService.getSecurityToken()
        .pipe(retry(5))
        .subscribe(
          (securityToken) => {
            this.chargify = new Chargify();
            this.chargify.load({
              securityToken: securityToken,
              publicKey: environment.chargify.publicKey,
              type: 'card',
              serverHost: environment.chargify.host,
              fields: {
                number: {
                  selector: '#chargify-number',
                  label: 'Number',
                  required: true
                },
                month: {
                  selector: '#chargify-month',
                  label: 'Month',
                  required: true,
                },
                year: {
                  selector: '#chargify-year',
                  label: 'Year',
                  required: true
                },
                cvv: {
                  selector: '#chargify-cvv',
                  label: 'CVV code',
                  required: false
                }
              }
            });
            console.debug("Chargify configured");
            this._chargifyIframeLoaded = true;
          },
          (err) => {
            console.error("There was a problem in getting the chargify security token", err);
          }
        );
    }
  }

  private setupLibraryRoles() {
    this.libraryMetadataService.getRoles(this._catalogItem.libraryTemplateId).subscribe((roles) => {
      this._libraryRoles = roles;
      this.buildForm();
      this.spinner.hide();
    }, err => {
      console.warn("error while getting roles from metadata", err);
    });
  }

  private updateNrUsers(nrUsers) {
    if (nrUsers >= this.libSharingConfig.sharingData.length) {
      this.paymentData.get('nrUsers').setValue(nrUsers);
      this.usersPurchased = nrUsers;
      const comp: ChargifyComponent = this.catalogItem.userByVolumeChargifyComponent;
      let rightPriceBracket: PriceBracket = new PriceBracket();
      for (const priceBracket of comp.prices) {
        if (nrUsers >= priceBracket.starting_quantity && nrUsers <= priceBracket.ending_quantity) {
          rightPriceBracket = priceBracket;
          break;
        }
      }
      this.finalPrice = Number((rightPriceBracket.unit_price * nrUsers).toFixed(2));
    } else {
      this.paymentData.get('nrUsers').setValue(this.libSharingConfig.sharingData.length);

    }
  }

  get minNbUsers(): number {
    return this._data.get('sharingData').value.length;
  }
}
