/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./integration.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./integration.component";
var styles_IntegrationComponent = [i0.styles];
var RenderType_IntegrationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IntegrationComponent, data: {} });
export { RenderType_IntegrationComponent as RenderType_IntegrationComponent };
export function View_IntegrationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "app-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
export function View_IntegrationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_IntegrationComponent_0, RenderType_IntegrationComponent)), i1.ɵdid(1, 114688, null, 0, i3.IntegrationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IntegrationComponentNgFactory = i1.ɵccf("ng-component", i3.IntegrationComponent, View_IntegrationComponent_Host_0, {}, {}, []);
export { IntegrationComponentNgFactory as IntegrationComponentNgFactory };
