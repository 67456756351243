<mat-toolbar class="app-toolbar">
  <img src="../assets/colored_logo_AODocs.svg" [ngClass.gt-sm]="'logo'" [ngClass.sm]="'logo-sm'"
       aria-label="Aodocs Catalog logo" (click)="GoToHomepage()">
  <div class="menu">
  <span class="logged-email">{{loggedUserEmail || ''}}</span>
  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item *ngIf="showAdminPanel" [routerLink]="['/admin']">
      <mat-icon>build</mat-icon>
      <span>Admin Panel</span>
    </button>
    <a href="https://support.aodocs.com/hc/en-us" target="_blank" mat-menu-item>
      <mat-icon>help_outline</mat-icon>
      <span>Help</span>
    </a>
    <a href="{{homePageTourURL}}" mat-menu-item>
      <mat-icon>launch</mat-icon>
      <span>Tour</span>
    </a>
  </mat-menu>
  </div>
</mat-toolbar>
