/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./catalog-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./catalog-item.component";
import * as i3 from "@angular/router";
import * as i4 from "../../_services/library.service";
import * as i5 from "../../_services/redirect-helper.service";
var styles_CatalogItemComponent = [i0.styles];
var RenderType_CatalogItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CatalogItemComponent, data: {} });
export { RenderType_CatalogItemComponent as RenderType_CatalogItemComponent };
export function View_CatalogItemComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_CatalogItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-catalog-item", [], null, null, null, View_CatalogItemComponent_0, RenderType_CatalogItemComponent)), i1.ɵdid(1, 114688, null, 0, i2.CatalogItemComponent, [i3.Router, i4.LibraryService, i3.ActivatedRoute, i5.RedirectHelperService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CatalogItemComponentNgFactory = i1.ɵccf("app-catalog-item", i2.CatalogItemComponent, View_CatalogItemComponent_Host_0, { _redirectToHubspotForm: "_redirectToHubspotForm" }, {}, []);
export { CatalogItemComponentNgFactory as CatalogItemComponentNgFactory };
