import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {UserProfile} from "../_model/user-profile.model";

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private readonly URL: string = '/api/profile';

  constructor(private httpClient: HttpClient) {
  }

  private _loggedUser: UserProfile;
  private _subject: Subject<UserProfile> = new BehaviorSubject<UserProfile>(this._loggedUser);

  get loggedUser(): UserProfile {
    return this._loggedUser;
  }

  getSelf(): Observable<UserProfile> {
    return this.httpClient.get<UserProfile>(this.URL + '/self');
  }

  getObservable(): Observable<UserProfile> {
    return this._subject.asObservable();
  }

  public initLoggedUser(): Observable<boolean> {
    const subject = new Subject<boolean>();
    this.getSelf().subscribe((data: UserProfile) => {
      this._loggedUser = data;
      this._subject.next(data);
      subject.next(true);
    }, err => {
      console.info("Couldn't set the logged user", err);
      subject.next(false);
    });

    return subject.asObservable();
  }

}
