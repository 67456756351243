<div class="container-centered">
  <mat-card>
    <div *ngIf="integrationDataResponse">
      <section *ngIf="integrationDataResponse.inProgress">
        <p> We are creating the structure for you</p>
        <span> <img src="../../../assets/loader.gif" width="30px" height="30px"></span>
      </section>
      <section *ngIf="!integrationDataResponse.link && !integrationDataResponse.inProgress">
        <p>There is no folder related to this form. </p>
      </section>
    </div>
    <div  *ngIf="!fpIdExists">
    <section>
      <p> No form Publisher ID was found.</p>
    </section>
    </div>
  </mat-card>
</div>

