import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {IntegrationComponent} from "./integration.component";
import {FormPublisherComponent} from "./form-publisher/form-publisher.component";


const routes: Routes = [
    {
      path: '',
      component: IntegrationComponent,
      children: [
        {
          path: 'form-publisher/:fp_id',
          component: FormPublisherComponent
        },
        {
          path: 'form-publisher',
          component: FormPublisherComponent
        }
      ]
    },
  ]
;

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IntegrationRoutingModule {
}
