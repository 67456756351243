import { HttpClient } from "@angular/common/http";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var LibraryService = /** @class */ (function () {
    function LibraryService(httpClient) {
        this.httpClient = httpClient;
        this.URL = '/api/libraries';
    }
    LibraryService.prototype.get = function (id) {
        return this.httpClient.get(this.URL + '/' + id);
    };
    LibraryService.prototype.getByCatalogItemId = function (id) {
        return this.httpClient.get(this.URL + '/catalogItem/' + id);
    };
    LibraryService.prototype.fetchForIntegration = function (data) {
        return this.httpClient.post(this.URL + '/integration', data);
    };
    LibraryService.prototype.getLink = function (id) {
        return this.httpClient.get(this.URL + '/' + id + '/link');
    };
    LibraryService.prototype.patch = function (id, data) {
        return this.httpClient.patch(this.URL + '/' + id, data);
    };
    LibraryService.prototype.getCategories = function (id) {
        return this.httpClient.get(this.URL + '/' + id + '/categories');
    };
    LibraryService.prototype.saveCategories = function (id, data) {
        return this.httpClient.post(this.URL + '/' + id + '/categories', data);
    };
    LibraryService.prototype.getSharingSettings = function (id) {
        return this.httpClient.get(this.URL + '/' + id + '/sharing-settings');
    };
    LibraryService.prototype.saveSharingSettings = function (id, data) {
        return this.httpClient.post(this.URL + '/' + id + '/sharing-settings', data);
    };
    LibraryService.prototype.getPluginsData = function (id) {
        return this.httpClient.get(this.URL + '/' + id + '/plugins');
    };
    LibraryService.ngInjectableDef = i0.defineInjectable({ factory: function LibraryService_Factory() { return new LibraryService(i0.inject(i1.HttpClient)); }, token: LibraryService, providedIn: "root" });
    return LibraryService;
}());
export { LibraryService };
