import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ChargifyTokenService {
  private readonly URL: string = '/api';

  constructor(private httpClient: HttpClient) {
  }

  getSecurityToken(): Observable<string> {
    return this.httpClient.get<string>(this.URL + '/self/chargify-token');
  }

}
