/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contact-registration-section.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../simple-toolbar/simple-toolbar.component.ngfactory";
import * as i3 from "../simple-toolbar/simple-toolbar.component";
import * as i4 from "../../_services/profile.service";
import * as i5 from "../../_services/redirect-helper.service";
import * as i6 from "@angular/router";
import * as i7 from "@angular/common";
import * as i8 from "./contact-registration-section.component";
import * as i9 from "@angular/material/dialog";
var styles_ContactRegistrationSectionComponent = [i0.styles];
var RenderType_ContactRegistrationSectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContactRegistrationSectionComponent, data: {} });
export { RenderType_ContactRegistrationSectionComponent as RenderType_ContactRegistrationSectionComponent };
function View_ContactRegistrationSectionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h3", [["class", "registration-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["To start your trial, tell us more about you."]))], null, null); }
function View_ContactRegistrationSectionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h3", [["class", "registration-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["To purchase the subscription, tell us more about you."]))], null, null); }
export function View_ContactRegistrationSectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "app-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-simple-toolbar", [], null, null, null, i2.View_SimpleToolbarComponent_0, i2.RenderType_SimpleToolbarComponent)), i1.ɵdid(2, 114688, null, 0, i3.SimpleToolbarComponent, [i4.ProfileService, i5.RedirectHelperService, i6.Router], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "registration-form-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactRegistrationSectionComponent_1)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactRegistrationSectionComponent_2)), i1.ɵdid(7, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "registration-form"], ["id", "registration"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_0 = _co.isTrial; _ck(_v, 5, 0, currVal_0); var currVal_1 = !_co.isTrial; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_ContactRegistrationSectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contact-registration-section", [], null, null, null, View_ContactRegistrationSectionComponent_0, RenderType_ContactRegistrationSectionComponent)), i1.ɵdid(1, 114688, null, 0, i8.ContactRegistrationSectionComponent, [i9.MatDialog, i6.ActivatedRoute, i5.RedirectHelperService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContactRegistrationSectionComponentNgFactory = i1.ɵccf("app-contact-registration-section", i8.ContactRegistrationSectionComponent, View_ContactRegistrationSectionComponent_Host_0, {}, {}, []);
export { ContactRegistrationSectionComponentNgFactory as ContactRegistrationSectionComponentNgFactory };
