import { HttpClient, HttpParams } from "@angular/common/http";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CatalogItemService = /** @class */ (function () {
    function CatalogItemService(httpClient) {
        this.httpClient = httpClient;
        this.URL = '/api/items';
        this.PROJECTION_VIEW = 'view';
    }
    CatalogItemService.prototype.getItems = function () {
        return this.httpClient.get(this.URL + '/search/published?isPublished=true');
    };
    CatalogItemService.prototype.getItem = function (id) {
        var params = new HttpParams().set('projection', this.PROJECTION_VIEW);
        return this.httpClient.get(this.URL + '/' + id, {
            params: params
        });
    };
    CatalogItemService.ngInjectableDef = i0.defineInjectable({ factory: function CatalogItemService_Factory() { return new CatalogItemService(i0.inject(i1.HttpClient)); }, token: CatalogItemService, providedIn: "root" });
    return CatalogItemService;
}());
export { CatalogItemService };
