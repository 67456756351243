import { OnDestroy, OnInit } from '@angular/core';
import { ResponsiveSizeInfoRx } from "ngx-responsive";
import { CatalogItemService } from "../../_services/catalog-item.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute } from "@angular/router";
var CatalogSectionComponent = /** @class */ (function () {
    function CatalogSectionComponent(responsiveSizeInfoRx, catalogItemService, activatedRoute, spinner) {
        this.responsiveSizeInfoRx = responsiveSizeInfoRx;
        this.catalogItemService = catalogItemService;
        this.activatedRoute = activatedRoute;
        this.spinner = spinner;
        this.filterByTrial = false;
        this.ribbonOpen = true;
    }
    Object.defineProperty(CatalogSectionComponent.prototype, "catalogItems", {
        get: function () {
            return this._catalogItems;
        },
        enumerable: true,
        configurable: true
    });
    CatalogSectionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.activatedRoute.data.subscribe(function (data) {
            if (data && data.filterByTrial === true) {
                _this.filterByTrial = true;
            }
            _this.getCatalogItem();
        });
    };
    CatalogSectionComponent.prototype.getCatalogItem = function () {
        var _this = this;
        this.spinner.show();
        var getObservable = this.catalogItemService.getItems();
        getObservable.subscribe(function (response) {
            _this._catalogItems = response._embedded.data;
            _this.spinner.hide();
        }, function (err) {
            console.error("remote error ", err);
            _this.spinner.hide();
        });
    };
    CatalogSectionComponent.prototype.ngOnDestroy = function () {
    };
    return CatalogSectionComponent;
}());
export { CatalogSectionComponent };
