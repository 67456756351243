import { OnInit } from '@angular/core';
import { ProfileService } from "../../_services/profile.service";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import { RedirectHelperService } from "../../_services/redirect-helper.service";
var SimpleToolbarComponent = /** @class */ (function () {
    function SimpleToolbarComponent(profileService, redirectHelperService, router) {
        this.profileService = profileService;
        this.redirectHelperService = redirectHelperService;
        this.router = router;
        this._showAdminPanel = false;
    }
    Object.defineProperty(SimpleToolbarComponent.prototype, "loggedUserEmail", {
        get: function () {
            return this._loggedUserEmail;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SimpleToolbarComponent.prototype, "showAdminPanel", {
        get: function () {
            return this._showAdminPanel;
        },
        enumerable: true,
        configurable: true
    });
    SimpleToolbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.profileService.getObservable().subscribe(function (data) {
            if (data) {
                _this._loggedUserEmail = data.email;
                _this._showAdminPanel = !!data.hasPurchases;
            }
        });
    };
    SimpleToolbarComponent.prototype.GoToHomepage = function () {
        if (this.loggedUserEmail == null) {
            this.router.navigate(['/public-catalog']);
        }
        else {
            this.router.navigate(['/catalog']);
        }
    };
    Object.defineProperty(SimpleToolbarComponent.prototype, "homePageTourURL", {
        get: function () {
            if (this.router.url.includes('/catalog/buy/')) {
                var id = this.redirectHelperService.catalogItemId;
                return environment.productTour.provisioningPage.replace('CATALOG_ITEM', id);
            }
            else {
                var context = void 0;
                if (this.loggedUserEmail == null) {
                    context = 'public-catalog';
                }
                else {
                    context = 'catalog';
                }
                return environment.productTour.homePage.replace('VARIABLE_CONTEXT', context);
            }
        },
        enumerable: true,
        configurable: true
    });
    return SimpleToolbarComponent;
}());
export { SimpleToolbarComponent };
