<div class="container-centered"
     [ngStyle.lt-sm]="{padding: '1rem 1rem'}"
     [ngStyle.sm]="{padding: '1rem 1rem'}"
     [ngStyle.gt-sm]="{padding: '1rem 8rem'}"
     [ngStyle.gt-md]="{padding: '1rem 12rem'}"
     [ngStyle.gt-lg]="{padding: '1rem 18rem'}">
  <!--Lib Description-->
  <section *ngIf="!catalogItem">
    <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
  </section>
  <mat-card *ngIf="catalogItem">
    <mat-card-header>
      <mat-card-title class="title-text">{{catalogItem.libraryTemplateName}}</mat-card-title>
      <div fxFlex></div>
      <app-price-tag *ngIf="!catalogItem.isFree" [price]="finalPrice"
                     [unit]="catalogItem.userByVolumeChargifyComponent.unit_name"></app-price-tag>
    </mat-card-header>
    <mat-card-content>
      <p class="description-text">
        {{catalogItem.description}}
      </p>
    </mat-card-content>
  </mat-card>
  <div style="margin-top: 10px"></div>

  <!--Fill form-->
  <mat-card *ngIf="!purchaseCompleted && this.initDone">
    <form [formGroup]="data" (keydown.enter)="$event.preventDefault()" (ngSubmit)="onLoadCaptcha()">
      <mat-accordion class="headers-align">

        <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle formGroupName="buyerData">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Personal data
            </mat-panel-title>
            <mat-panel-description>
              <span class="card-description">Type your personal data</span>
              <mat-icon class="accordion_icon">account_circle</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div>
            <mat-form-field fxFlex.xs="40" fxFlex.sm="25" fxFlex.md="25" fxFlex.gt-xs="14">
              <input matInput placeholder="First name"
                     [required]="true"
                     formControlName="firstName">
              <mat-error *ngIf="buyerData.get('firstName').invalid">Please fill this field.</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex.xs="40" fxFlex.sm="25" fxFlex.md="25" fxFlex.gt-xs="14">
              <input matInput placeholder="Last name"
                     [required]="true"
                     formControlName="lastName">
              <mat-error *ngIf="buyerData.get('lastName').invalid">Please fill this field.</mat-error>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field fxFlex.xs="51" fxFlex.sm="51" fxFlex.md="51" fxFlex.gt-xs="29">
              <input matInput placeholder="Email Address"
                     [required]="true"
                     formControlName="email">
              <mat-error *ngIf="buyerData.get('email').invalid">Please write a valid email address.</mat-error>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field fxFlex.xs="40" fxFlex.sm="25" fxFlex.md="25" fxFlex.gt-xs="14">
              <input matInput placeholder="Organization" formControlName="organization">
            </mat-form-field>
            <mat-form-field fxFlex.xs="40" fxFlex.sm="25" fxFlex.md="25" fxFlex.gt-xs="14">
              <input matInput placeholder="Phone" formControlName="phone">
              <mat-error *ngIf="buyerData.get('phone').invalid">Please use a valid phone number.</mat-error>
            </mat-form-field>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="data.get('paymentData')"
                             [expanded]="step === 2" (opened)="setStep(2); setupChargifyForCreditCard()"
                             hideToggle formGroupName="paymentData">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Credit card
            </mat-panel-title>
            <mat-panel-description>
              <span class="card-description">Type your credit card info</span>
              <mat-icon class="accordion_icon">credit_card</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div>
            <form #chargifyForm>
              <div fxLayout="column">
                <div style="max-width: 316px">
                  <div id="chargify-number"></div>
                </div>
                <div fxLayout="row wrap" fxLayoutGap="8px">
                  <div style="width: 120px">
                    <div id="chargify-month"></div>
                  </div>
                  <div style="width: 120px">
                    <div id="chargify-year"></div>
                  </div>
                  <div style="width: 120px">
                    <div id="chargify-cvv"></div>
                  </div>
                </div>
              </div>

              <mat-progress-bar mode="indeterminate" fxFlex="30"
                                *ngIf="!chargifyIframeLoaded"></mat-progress-bar>
              <!--              Relay data to Chargify.js iframe-->
              <div class="host-field">
                <input type="hidden" class="host-input" data-chargify="firstName"
                       [value]="paymentData.get('firstName').value"/>
              </div>
              <div class="host-field">
                <input type="hidden" class="host-input" data-chargify="lastName"
                       [value]="paymentData.get('lastName').value"/>
              </div>
              <div class="host-field">
                <input type="hidden" class="host-input" data-chargify="address"
                       [value]="paymentData.get('billingAddress').value"/>
              </div>
              <div class="host-field">
                <input type="hidden" class="host-input" data-chargify="city"
                       [value]="paymentData.get('billingCity').value"/>
              </div>
              <div class="host-field">
                <input type="hidden" class="host-input" data-chargify="state"
                       [value]="paymentData.get('billingState').value"/>
              </div>
              <div class="host-field">
                <input type="hidden" class="host-input" data-chargify="country"
                       [value]="paymentData.get('billingCountry').value"/>
              </div>
              <div class="host-field">
                <input type="hidden" class="host-input" data-chargify="zip"
                       [value]="paymentData.get('billingZipCode').value"/>
              </div>
            </form>

          </div>

          <div>
            <mat-form-field>
              <input matInput placeholder="Fist Name on Account"
                     [required]="true"
                     formControlName="firstName">
              <mat-error *ngIf="paymentData.get('firstName').invalid">Please fill this field.</mat-error>
            </mat-form-field>

            <mat-form-field>
              <input matInput placeholder="Last Name on Account"
                     [required]="true"
                     formControlName="lastName">
              <mat-error *ngIf="paymentData.get('lastName').invalid">Please fill this field.</mat-error>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field fxFlex.gt-sm="40">
              <textarea matInput placeholder="Billing Address" formControlName="billingAddress"></textarea>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <input matInput placeholder="Billing Zip Code" formControlName="billingZipCode">
            </mat-form-field>
            <mat-form-field>
              <input matInput placeholder="Billing City" formControlName="billingCity">
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>Billing Country</mat-label>
              <mat-select formControlName="billingCountry" (selectionChange)="billingCountryUpdated($event)">
                <mat-option *ngFor="let country of countries" [value]="country.Code"
                            style="height: 44px;">
                  {{country.Name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-progress-bar mode="indeterminate" *ngIf="loadingStates"></mat-progress-bar>
            <mat-form-field *ngIf="!loadingStates">
              <mat-label>Billing State</mat-label>
              <mat-select formControlName="billingState">
                <mat-option *ngFor="let state of states" [value]="state.Code"
                            style="height: 44px;">
                  {{state.Name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field fxFlex.gt-sm="40">
              <input matInput type="number" [min]="minNbUsers"
                      placeholder="Number of desired users"
                     formControlName="nrUsers">
            </mat-form-field>
          </div>

        </mat-expansion-panel>

        <span id="optional-section">
        <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Sharing settings
            </mat-panel-title>
            <mat-panel-description>
              <span class="card-description"> Specify roles and access rights (Optional)</span>
              <mat-icon class="accordion_icon">people</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div style="margin-bottom: 2em" *ngIf="isAppInStoreMode()">
            <app-lib-roles-description [roles]="libraryRoles"></app-lib-roles-description>
          </div>

          <div style="margin-bottom: 2em" *ngIf="isAppInTrainingMode()">
            <span class="font-light">All people will be added as library admin.</span>
          </div>

          <app-lib-sharing-config (userNrChange)='updateNrUsers($event)' [libraryRoles]="libraryRoles"
                                  #libSharingConfig></app-lib-sharing-config>

        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)"
                             hideToggle formGroupName="librarySettingsData">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Library settings
            </mat-panel-title>
            <mat-panel-description>
              <span class="card-description"> Specify library customization (Optional)</span>
              <mat-icon class="accordion_icon">build</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <mat-checkbox formControlName="cloneWithDocumentsChecked" color="primary">Copy example documents into application</mat-checkbox>

        </mat-expansion-panel>
      </span>
      </mat-accordion>

      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="2px" style="margin: 10px 0">
        <mat-slide-toggle [checked]="termsAccepted"
                          (change)="termsAccepted = $event.checked"
                          color="primary">
        </mat-slide-toggle>
        <span class="terms-of-service">I've read and I accept the <a href="https://www.aodocs.com/terms-of-service/"
                                                                     target="_blank">Terms of Service.</a></span>
      </div>

      <div fxLayout="row" fxLayoutAlign="end center" *ngIf="startButtonClicked">
        <re-captcha (resolved)="onSubmit($event)" [siteKey]="recaptchaSiteKey"></re-captcha>
      </div>

      <mat-card-actions>
        <button mat-button (click)="goBackToCatalog()" type="button">Cancel</button>
        <div fxFlex></div>
        <button mat-flat-button color="primary" type="submit"
                class="purchase-button"
                [disabled]="!data.valid || !termsAccepted">
          <span fxLayout="row" fxLayoutAlign="space-between center">
            Start your demo
          </span>
        </button>
      </mat-card-actions>
    </form>
  </mat-card>

  <mat-card *ngIf="purchaseCompleted">
    <mat-progress-bar *ngIf="!purchase" mode="indeterminate" color="primary"></mat-progress-bar>
    <div *ngIf="purchase && purchase.status == 'OK'">
      <h2 style="margin-bottom: 0.5em">Reference ID: #{{purchase.id}}</h2>
      <span class="font-light" *ngIf="purchase.libraryName">Library name: {{purchase.libraryName}}</span>
      <mat-progress-bar mode="buffer" *ngIf="!purchase.libraryUrl"></mat-progress-bar>
      <mat-divider style="margin-top: 0.5em"></mat-divider>
      <div fxLayout="row" fxLayout.lt-md="column">
        <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100" fxLayoutGap="20px">
          <h3>Personal data</h3>
          <div style="overflow: auto">
            <table class="details-table">
              <tr>
                <td><label>Fist name</label></td>
                <td>{{buyerData.get('firstName').value}}</td>
              </tr>
              <tr>
                <td><label>Last name</label></td>
                <td>{{buyerData.get('lastName').value}}</td>
              </tr>
              <tr>
                <td><label>E-mail</label></td>
                <td>{{buyerData.get('email').value}}</td>
              </tr>
              <tr>
                <td><label>Organization</label></td>
                <td>{{buyerData.get('organization').value || '-'}}</td>
              </tr>
              <tr>
                <td><label>Phone</label></td>
                <td>{{buyerData.get('phone').value || '-'}}</td>
              </tr>
            </table>
          </div>
        </div>

        <div fxLayout="column" style="min-width: 250px;" *ngIf="data.get('paymentData')">
          <h3>Credit Card</h3>
          <div style="overflow: auto">
            <table class="details-table">
              <tr>
                <td><label>Fist name on account</label></td>
                <td>{{paymentData.get('firstName').value || '-'}}</td>
              </tr>
              <tr>
                <td><label>Last name on account</label></td>
                <td>{{paymentData.get('lastName').value || '-'}}</td>
              </tr>
              <tr>
                <td><label>Billing Address</label></td>
                <td>{{paymentData.get('billingAddress').value || '-'}}</td>
              </tr>
              <tr>
                <td><label>Billing City</label></td>
                <td>{{paymentData.get('billingCity').value || '-'}}</td>
              </tr>
              <tr>
                <td><label>Billing State</label></td>
                <td>{{paymentData.get('billingState').value || '-'}}</td>
              </tr>
              <tr>
                <td><label>Billing Country</label></td>
                <td>{{paymentData.get('billingCountry').value || '-'}}</td>
              </tr>
              <tr>
                <td><label>Billing Zip code</label></td>
                <td>{{paymentData.get('billingZipCode').value || '-'}}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div style="margin-top: 10px">
        <mat-divider></mat-divider>
        <div style="padding: 6px; margin-top: 6px">
          <div fxFlex></div>
          <div *ngIf="false"><span>Total: ${{finalPrice}}/month </span></div>
        </div>
      </div>
      <mat-card-actions>
        <button fxHide.lt-md mat-button (click)="goBackToCatalog()" type="button">Back to catalog</button>
        <button fxShow.lt-md fxHide mat-icon-button (click)="goBackToCatalog()" type="button">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <div fxFlex></div>
        <div *ngIf="!purchase.libraryUrl" fxLayoutAlign="center center">
          <span class="loading" style="margin-right: 6px"
                matTooltip="Wanna leave this page? Don't worry. You'll receive an email when the process is completed.">
            Library creation in progress</span>
        </div>
        <div *ngIf="purchase.libraryId" fxLayoutGap="6px"
             fxLayout.lt-sm="column" fxLayoutAlign="center center">
          <a [routerLink]="['/admin/library']" [queryParams]="{libId: purchase.libraryId}" target="_blank">
            <button mat-raised-button>
              Configure
            </button>
          </a>
          <a [href]="purchase.libraryUrl" target="_blank">
            <button mat-flat-button color="primary">
              Open in AODocs
            </button>
          </a>
        </div>
      </mat-card-actions>
    </div>

    <div *ngIf="purchase && purchase.status == 'ERROR'">
      <div>
        <h3>Something is not right</h3>
        <p>Something went wrong while processing your data.</p>
        <p>We are working on it, but feel free to reach support for any explanation.</p>
        <p>Your reference id is <b>#{{purchase.id}}</b>.</p>
        <p *ngIf="purchase.errorMsg">
          <span class="font-code">{{purchase.errorMsg}}</span>
        </p>
        <mat-divider></mat-divider>
      </div>
      <mat-card-actions>
        <button fxHide.lt-md mat-button (click)="goBackToCatalog()" type="button">Back to catalog</button>
        <button fxShow.lt-md fxHide mat-icon-button (click)="goBackToCatalog()" type="button">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <div fxFlex></div>
        <button mat-flat-button color="primary" (click)="clearPurchaseData()">
          Try again
        </button>
      </mat-card-actions>
    </div>

  </mat-card>
</div>
