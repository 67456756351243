import {Component, OnDestroy, OnInit} from '@angular/core';
import {ResponsiveSizeInfoRx} from "ngx-responsive";
import {CatalogItemService} from "../../_services/catalog-item.service";
import {CatalogItem} from "../../_model/catalog-item.model";
import {NgxSpinnerService} from "ngx-spinner";
import {ApiResponse} from "../../_model/api-response.model";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-catalog-section',
  templateUrl: './catalog-section.component.html',
  styleUrls: ['./catalog-section.component.scss']
})
export class CatalogSectionComponent implements OnInit, OnDestroy {

  constructor(private responsiveSizeInfoRx: ResponsiveSizeInfoRx,
              private catalogItemService: CatalogItemService,
              private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService) {
  }

  private _catalogItems: CatalogItem[];
  private filterByTrial = false;
  ribbonOpen = true;

  get catalogItems(): Array<CatalogItem> {
    return this._catalogItems;
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe(data => {
      if (data && data.filterByTrial === true) {
        this.filterByTrial = true;
      }
      this.getCatalogItem();
    });
  }

  private getCatalogItem() {
    this.spinner.show();
    const getObservable = this.catalogItemService.getItems();
    getObservable.subscribe((response: ApiResponse<CatalogItem>) => {
        this._catalogItems = response._embedded.data;
        this.spinner.hide();
      },
      err => {
        console.error("remote error ", err);
        this.spinner.hide();
      });
  }

  ngOnDestroy(): void {
  }

}
