import { HttpClient } from "@angular/common/http";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ContactService = /** @class */ (function () {
    function ContactService(httpClient) {
        this.httpClient = httpClient;
        this.URL = '/api/contact';
    }
    ContactService.prototype.getSelf = function () {
        return this.httpClient.get(this.URL + '/self');
    };
    ContactService.ngInjectableDef = i0.defineInjectable({ factory: function ContactService_Factory() { return new ContactService(i0.inject(i1.HttpClient)); }, token: ContactService, providedIn: "root" });
    return ContactService;
}());
export { ContactService };
