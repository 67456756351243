import {Component, Input, OnInit} from '@angular/core';
import {RoleMetadata} from "../../_model/library-metadata.model";

@Component({
  selector: 'app-lib-roles-description',
  templateUrl: './lib-roles-description.component.html',
  styleUrls: ['./lib-roles-description.component.scss']
})
export class LibRolesDescriptionComponent implements OnInit {

  @Input()
  roles: Array<RoleMetadata> = new Array<RoleMetadata>();

  constructor() {
  }

  ngOnInit() {
  }

}
