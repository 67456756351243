<mat-card>
  <mat-card-title>
    <h3>Something is wrong</h3>
  </mat-card-title>
  <mat-card-content>
    <p>
      Looks like something is wrong. Please check your connection and refresh the page.
    </p>
    <p>
      If the problem persists, please contact support.
    </p>
  </mat-card-content>
</mat-card>
