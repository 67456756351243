<mat-card class="catalog-card"
          [ngStyle.gt-sm]="{height: '100%'}"
          fxLayout="column">
  <div class="header">
    <img class="library-logo" alt="Library Logo"
         src="{{item.libraryTemplateLogo || '../../../assets/catalog-placeholder.png'}}">
  </div>
  <mat-card-content fxLayout="column">
    <div class="title-text">
      <span > {{item.libraryTemplateName}}</span>
    </div>
    <div class="title-box" fxLayout="column" fxFlex fxLayoutAlign="space-evenly start">
     <!-- <mat-card-title class="title-text">{{item.libraryTemplateName}}</mat-card-title>-->
      <!--      <app-price-tag-banner [price]="startingPrice"></app-price-tag-banner> -->
      <mat-card-subtitle class="description-text"> {{item.description}}</mat-card-subtitle>
    </div>
    <div fxLayout="row wrap" class="bullet-point-container">
      <span class="bullet-point-item"
            *ngFor="let bulletPoint of bulletPoints"
            fxLayoutAlign="center center">
        <mat-icon class="bullet-point-check">check</mat-icon>
       <span class="bullet-point-text"> {{bulletPoint | capitalize}} </span>
      </span>
    </div>

  </mat-card-content>

  <div fxFlex></div>
  <div fxFlex></div>
  <div fxLayout="row" fxLayoutAlign="space-between center">

    <div>
      <button *ngIf="false" mat-flat-button class="purchase-button" color="primary" (click)="moveToNextSection(item.id,false)">
          <span fxLayout="row" fxLayoutAlign="space-between center" fxHide.lt-md="">
            Purchase Subscription
          </span>
        <span fxLayout="row" fxLayoutAlign="space-between center" fxHide.md="" fxHide.gt-md="">
            Purchase
          </span>
      </button>
    </div>
    <div>
      <button mat-flat-button class="purchase-button" color="primary"  (click)="moveToNext(item.id)"
               title="Your demo will be automatically activated after the trial period.&#13;For this, you will have to enter your credit card details during the sign-up process.">
          <span  *ngIf="!item.purchaseLink" fxLayout="row" fxLayoutAlign="space-between center">
          Try now!
          </span>
        <span  *ngIf="item.purchaseLink" fxLayout="row" fxLayoutAlign="space-between center">
          Go to!
          </span>
      </button>
    </div>

  </div>
</mat-card>
